import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { scrappingUrl } from "../../api/constants";
import { Button } from "../button/Button";
import CustomModal from "../custom-modal/CustomModal";
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";
import { ReactComponent as Upload } from "../../static/assets/svg/upload.svg";
import { ReactComponent as Document } from "../../static/assets/svg/document.svg";
import { directMail, fetcher, vdeChecker } from "../../api/SWR/fetchers";
import useProduct from "../../api/SWR/useProduct";
import { certificateAPI, materialAPI } from "../../api/apiRequests";
import _ from "lodash";
import Loader from "../loader/Loader";
import useUser from "../../api/SWR/useUser";
import EditCertificateField from "../ui/edit-certificate-field/EditCertificateField";
import AddCertificate from "../add-certificate/AddCertificate";
import axios from "axios";
import EditMaterialField from "../ui/edit-certificate-field/EditMaterialField";
import { Input } from "../input/Input";
import { ROHS_COMPONENT_STATUSES } from "../../constants/enums";


const tableHeadings = ["PDF", "COLLECTION DATE", "Type", "Approve"];


const RoHSCertificatesList = ({
  componentName,
  materialCertificatesID,
  productId,
  setRoHSCollectionDate,
  closeModal,
  requiredCertificates,
  manufactureName,
  type,
  rohsApprove,
  rohsLocalPath,
  collectionDate,
}: {
  materialCertificatesID: number | boolean;
  componentName: string | undefined;
  productId: number;
  closeModal: () => void;
  requiredCertificates: any;
  manufactureName: string | undefined;
  type: string | undefined;
  collectionDate: string | undefined;
  rohsApprove: boolean;
  rohsLocalPath: string;
  setRoHSCollectionDate: any;
}) => {
  const [idCertificateToUpload, setIdCertificateToUpload] = useState<
    boolean | number
  >(false);
  const [file, setFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [webLoading, setWebLoading] = useState(false);

  const [isSetDate, setDate] = useState(collectionDate);

  const [isApprove, setIsApprove] = useState(rohsApprove);
  const [certificatesToDelete, setCertificatesToDelete] = useState();
  const [isMailLoading, setIsMailLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [iframeModalData, setIframeModalData] = useState<
    false | { link: string; body: string }
  >(false);
//   useEffect( ()=>{
//     approveStatus();

//  },[isApprove]);

 const approveStatus = async ()=>{
     if (isApprove) {
    await materialAPI.updateMaterialValues(+materialCertificatesID,ROHS_COMPONENT_STATUSES.IN_REVIEW,"rohsStatus") 
  }else{
    await materialAPI.updateMaterialValues(+materialCertificatesID,ROHS_COMPONENT_STATUSES.APPROVED,"rohsStatus")     
  }
  setIsApprove(!isApprove);
  await mutate();
  }
  const { data, mutate, isLoading: dataLoading } = useProduct(productId);
  const [isAddCertificateModalShown, setIsAddCertificateModalShown] =
    useState(false);

  const user = useUser();
  const isAdmin = user.data.role === 1;
  if (dataLoading) {
    return (
      <div className="relative">
        <Loader />
      </div>
    );
  }


  const showingData = data?.materials?.filter(
    (material: any) => material.id === materialCertificatesID
  )?.[0]?.certificates;

  const onUploadHandler = async () => {
    try {
      setIsLoading(true);
      const form_data = new FormData();
      if (file !== null) {
        form_data.append("file", file);
        form_data.append("id", materialCertificatesID.toString());

        await fetcher({
          key: "material/rohs/upload",
          data: form_data,
        });
        await materialAPI.updateMaterialValues(+materialCertificatesID,"1","rohsStatus");
        await materialAPI.updateMaterialValues(+materialCertificatesID,false,"isRohsSent");
        await materialAPI.updateMaterialValues(+materialCertificatesID,"Email Manufacturer"," rohsMailText");
      }
      await mutate();
      setIsLoading(false);
      setIdCertificateToUpload(false);
      closeModal();
      toast.success("Certificate file uploaded!");
    } catch (e) {
      toast.error("Something went wrong");
      setIsLoading(false);
    }
  };

  const sendDirectMail = async (cert: any) => {
    try {
      toast.success("Email Sent");
      cert.productId = productId;
      await directMail(cert);
    } catch (e) {
      toast.error("Something went wrong");
    }
  };

  const handleAddCertificateToDelete = (id: any) => {    
    if (!certificatesToDelete) {
       setCertificatesToDelete(id);
    }else{
       setCertificatesToDelete(undefined)
    }
  };

  const handleDeleteCertificates = async (id: number) => {
    try {
      setIsLoading(true);
      await materialAPI.updateMaterialValues(id,"","rohsLocalPath"); 
      await materialAPI.updateMaterialValues(id,"","rohsCollectionDate"); 
      await mutate();
      toast.success("Deleted!");
      closeModal();
      setIsLoading(false);
    } catch (e) {
      toast.error("Something went wrong");
      setIsLoading(false);
    }
  };

  const handleChangeEditMaterialMode = async (isEditMode: boolean) => {
    if (isEditMode) {
      await mutate();
    }
    setIsEditMode(!isEditMode);
  };

  const renderTableInput = (
    value: string | undefined,
    type: string,
    id: number
  ) => {
    if (isEditMode) {      
      return <EditMaterialField currentValue={value} type={type} id={id} setRoHSCollectionDate={setRoHSCollectionDate} />;
    } else return value
    ;
  };

  const renderSource = (
    source: string,

    serviceName: string | undefined
  ) => {
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        className="text-primary_color underline"
        href={source}
        target="_blank"
        rel="noopener"
      >
        {serviceName ? serviceName : "Source link"}
      </a>
    );
    // }
  };

  if (isAdmin && !tableHeadings.includes("DELETE")) {
    tableHeadings.push("DELETE");
  }
  return (
    <>
      {webLoading ? (
        <Loader />
      ) : (
        <div className={styles.certificatesModalWrap}>
          <div className={"flex justify-between"}>
            <h2 className="text-4xl leading-10 font-medium mb-4 text-primary-500">
              RoHS Certificate
            </h2>
            <div>
              {isAdmin && rohsLocalPath && (
                <Button
                  label={
                    isEditMode ? "Turn off edit mode" : "Turn on edit mode"
                  }
                  className={"mr-2.5"}
                  onClick={() => handleChangeEditMaterialMode(isEditMode)}
                />
              )}

              {isAdmin && !rohsLocalPath && (
                <Button
                  label={"Upload RoHS Certificate"}
                  className={"mr-2.5"}
                  onClick={() => setIdCertificateToUpload(true)}
                />
              )}
              

{isAdmin && certificatesToDelete && (
                <Button
                  label={"Delete"}
                  className={"mr-2.5"}
                  loading={isLoading}
                  onClick={() => handleDeleteCertificates(certificatesToDelete) }
                />
              )}
            </div>
          </div>

          <div className={styles.tableWrapper}>
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  {tableHeadings.map((heading, index) => (
                    <th
                      key={index}
                      scope="col"
                      className="px-6 py-3 text-left text-xs leading-4 font-medium tracking-wider uppercase text-gray-500"
                    >
                      {heading}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white">
             { !rohsLocalPath ? <p></p> :  <tr className={"bg-gray-50"}>
                  <td className="whitespace-nowrap px-3 py-3 text-sm font-medium text-gray-900 sm:pl-6">
                    <div style={{ maxWidth: 150 }}>
                      {renderSource(rohsLocalPath, manufactureName)}
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-3 text-sm font-medium text-gray-900 sm:pl-6">
                    <div style={{ maxWidth: 150 }}>
                      {renderTableInput(
                        collectionDate,
                        "rohsCollectionDate",
                        +materialCertificatesID
                      )}
                       {/* <Input type="date" label="date here" onChange={(e)=>{
                          setDate(e.target.value);
                        console.log(e.target.value);
                        
                       }} value={isSetDate} disabled={!isEditMode} /> */}
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-3 text-sm font-medium text-gray-900 sm:pl-6">
                    <div style={{ maxWidth: 150 }}>{type}</div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-3 text-center align-top text-sm text-gray-500">
                    <input
                      className="text-green-600 focus:ring-green-600"
                      type={"checkbox"}
                      checked={isApprove}
                      onChange={async () =>                        
                        // setIsApprove(!isApprove)
                        approveStatus()
                      }
                    />
                  </td>
                 {isAdmin && <td className="whitespace-nowrap px-3 py-3 text-center align-top text-sm text-gray-500">
                    <input
                      className="text-green-600 focus:ring-green-600"
                      type={"checkbox"}
                      onChange={() =>
                        handleAddCertificateToDelete(+materialCertificatesID)
                      }
                    />
                  </td>}
                </tr>}
              </tbody>
            </table>
          </div>
        </div>
      )}
      <CustomModal
        setModalShown={() => setIdCertificateToUpload(false)}
        isShown={!!idCertificateToUpload}
      >
        <div className={styles.addModalWrap}>
          <h2 className="text-4xl leading-10 font-medium mb-4 text-primary-500">
            Upload RoHS Certificate
          </h2>
          <div className={"mt-6"}>
            <FileUploader
              handleChange={setFile}
              onSizeError={(err: string) => toast.error(err)}
              onTypeError={(err: string) => toast.error(err)}
              name="file"
              classes={"DragDropFiles"}
              types={["pdf"]}
              maxSize={10}
              children={
                <div className="DragDropFiles__body">
                  <Upload className="DragDropFiles__icon" />
                  <div className="DragDropFiles__body__title">
                    Upload a file <span>or drag and drop</span>
                  </div>
                  <div className="DragDropFiles__body__subtitle">
                    PDF up to 10MB
                  </div>
                </div>
              }
            />
            {}

            {file ? (
              <div className="AddProduct__fileView">
                <div className="AddProduct__fileView__left">
                  <Document className="AddProduct__fileView__icon" />
                  <div className="AddProduct__fileView__text">{file?.name}</div>
                </div>
                <div
                  className="AddProduct__fileView__right"
                  onClick={() => setFile(null)}
                >
                  Remove
                </div>
              </div>
            ) : null}
          </div>
          <div className={"mt-6 flex justify-end"}>
            <Button
              label="Upload"
              disabled={!file || isLoading}
              onClick={() => onUploadHandler()}
            />
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default RoHSCertificatesList;
