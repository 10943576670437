import React, { useCallback, useEffect, useState } from "react";
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, PlusIcon } from "@heroicons/react/solid";

import { Button, BUTTON_COLORS } from "../../components/button/Button";
import "./ProductsContainer.scss";
import * as Yup from "yup";
import _ from "lodash";
import styles from "../../components/materials-table/styles.module.scss";

import useUser from "../../api/SWR/useUser";
import CustomModal from "../../components/custom-modal/CustomModal";
import MaterialCertificatesList from "../../components/material-certificates-list/MaterialCertificatesList";
import MaterialComments from "../../components/material-comments/MaterialComments";
import useAllComponents from "../../api/SWR/useAllComponents";
import MaterialComponents from "../../components/all-material/MaterialComponents";
import AddCertificate from "../../components/add-certificate/AddCertificate";
import MaterialComponentsCertificatesList from "../../components/material-certificates-list/MaterialComponentsCertificatesList";
import Loader from "../../components/loader/Loader";

export default function ComponentsContainer({
}: {
  materials: any[];
  wsCertificates: any;
  mutate: any;
  isDetect: any;
  setIsDetect: any;
  materialsToDelete: number[];
  setMaterialsToDelete: any;
  productId: number;
  productStatus: any;
  isEditMode: boolean;
  mainPageLoader: (event: boolean) => void;
}) {
  const { data: userData } = useUser();
  const isAdmin = userData.role === 1;
  const userRole = userData.role;
  const itemsPerPage = 10;
  const [components, setComponents] = useState<any>([]);

  const [isLoadingNow, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedMaterial, setSelectedMaterial] = useState<any>();
  const [showingData, setShowingData] = useState<any>();
  const [showingData2, setShowing2Data] = useState<any>();
  const [showProduct, setShowProduct] = useState<boolean>(false);

  // const pageCount = Math.ceil(materials?.length / itemsPerPage);
  const [materialCertificatesID, setMaterialCertificatesID] = useState<
    number | boolean
  >(false);

  const [rohsCertificatesID, setRoHSCertificatesID] = useState<
    number | boolean
  >(false);

  const [rohsCollectionDate, setRoHSCollectionDate] = useState<string>();
  const [rohsCertificateApprove, setRoHSCertificateApprove] =
    useState<boolean>(false);
  const [roHSCertificatesPath, setRoHSCertificatesPath] = useState<string>("");

  const [manufactureName, setManufactureName] = useState<string>();

  const [certificateType, setCertificateType] = useState<string>();

  const [materialCertificates, setMaterialCertificates]: any = useState({});
  const [materialCertificatesName, setMaterialCertificatesName] =
    useState<string>();
  const [requiredCertificates, setRequiredCertificates] = useState();
  const [isCommentsModalShown, setCommentsModalShown] = useState<null | number>(
    null
  );
  const [productList, setProductList] = useState<null | []>();
  const [isProductList, isSetProductList] = useState(false);
  const [criticalFilter, setCriticalFilter] = useState<string | boolean>(false);
  const [order, setOrder] = useState("DSC");
  const [materialId, setMaterialId] = useState<number | boolean>(false);
  const [manufactureMails, setManufactureMails] = useState<boolean>(false);
  const { data, error, mutate, isLoading } = useAllComponents();



  useEffect(() => {
    if (!error && data) {
      sortingRecord("Certification Records", data);
    }
  }, [data, error]);



  useEffect(() => {
    fetchMaterialStatus();
  }, []);
  function fetchMaterialStatus() {
    // getMaterialStatus().then((response: any) => {
    //   setMaterialStatus(response.data);
    // });
  }

  const showPagesCount = (pageCount: number, currentPage: number) => {
    let array: number[] = Array.from(
      new Set([
        1,
        2,
        3,
        currentPage < 2 ? 1 : currentPage - 1,
        currentPage,
        currentPage >= pageCount ? pageCount : currentPage + 1,
        pageCount - 2,
        pageCount - 1,
        pageCount,
      ])
    )
      .sort((a, b) => a - b)
      .filter((el) => el > 0 && el <= pageCount);
    for (let i = 1; i < array.length; i++) {
      if (array[i] - 1 !== array[i - 1]) {
        array = [...array.slice(0, i), 0, ...array.slice(i)];
        i++;
      }
    }
    return array;
  };

  const sortingRecord = (col: any, data: any) => {
    // setIsLoading(true);

    if (order === "ASC") {
      const sorted =
        data &&
        [...data].sort((a, b) => {
          switch (col) {
            // case "manufacturedPlant":
            //   let RawDataA = JSON.parse(a["rawData"]);
            //   let RawDataB = JSON.parse(b["rawData"]);
            //   return RawDataA[col].toLowerCase() > RawDataB[col].toLowerCase()
            //     ? 1
            //     : -1;
            case "MANUFACTURER":
              return a["manufacturer"].toLowerCase() > b["manufacturer"].toLowerCase() ? 1 : -1;
            case "MANUFACTURER’S COMPONENT NAME":
              return a["componentName"].toLowerCase() > b["componentName"].toLowerCase() ? 1 : -1;
            case "TYPE":
              if (a["type"] && b["type"])
                return a["type"].toLowerCase() > b["type"].toLowerCase() ? 1 : -1;
            case "Certification Records":
              return a["allCertificates"].length > b["allCertificates"].length ? 1 : -1;
            case "IN PRODUCT":
              return a["product"].length > b["product"].length ? 1 : -1;
            default:
              return a[col] > b[col] ? 1 : -1;
          }
        });
      setComponents(sorted);
      setOrder("DSC");
    } else {
      const sorted =
        data &&
        [...data].sort((a, b) => {
          switch (col) {
            //  case "serviceName":
            //     return a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1;
            //   case "manufacturedPlant":
            //     let RawDataA = JSON.parse(a["rawData"]);
            //     let RawDataB = JSON.parse(b["rawData"]);
            //     return RawDataA[col].toLowerCase() < RawDataB[col].toLowerCase()
            //       ? 1
            //       : -1;
            //   case "id":
            //     return a[col] < b[col] ? 1 : -1;
            case "MANUFACTURER":
              return a["manufacturer"].toLowerCase() > b["manufacturer"].toLowerCase() ? -1 : 1;
            case "MANUFACTURER’S COMPONENT NAME":
              return a["componentName"].toLowerCase() > b["componentName"].toLowerCase() ? -1 : 1;
            case "TYPE":
              if (a["type"] && b["type"])
                return a["type"].toLowerCase() > b["type"].toLowerCase() ? -1 : 1;
            case "Certification Records":
              return a["allCertificates"].length > b["allCertificates"].length ? -1 : 1;
            case "IN PRODUCT":
              return a["product"].length > b["product"].length ? -1 : 1;
            default:
              return a[col] < b[col] ? 1 : -1;
          }
        });
      setComponents(sorted);
      setOrder("ASC");
    }
    // setIsLoading(false);
  };

  let tableHeadings = [
    "MANUFACTURER’S COMPONENT NAME",
    "MANUFACTURER",
    "TYPE",
    "COMMENTS",
    "IN PRODUCT",
    "Certification Records"
  ];

  return (
    <>

      {
        isLoading ? <Loader /> :
          <div>
            <div className="mt-8 flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="overflow-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="table-auto divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          {tableHeadings.map((heading, index) => {
                            if (
                              userData.role === 4 &&
                              index === 4


                            )
                              return null;
                            return (
                              <th
                                onClick={() => sortingRecord(heading, components)}
                                key={index}
                                scope="col"
                                className="App__container px-3 py-3 text-left text-xs leading-4 font-black tracking-wider uppercase text-gray-900"
                              >
                                {heading}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {components &&
                          components.map((material: any, index: any) => {
                            return (
                              <>
                                <tr
                                  key={material.id}
                                  className={
                                    index % 2 === 0 ? undefined : "bg-gray-50"
                                  }
                                >


                                  <td className={`whitespace-nowrap px-3 py-3 align-top text-sm ${material?.showAlternative ? 'text-black-500' : 'text-gray-500'
                                    }`}>
                                    {material?.componentName}

                                  </td>
                                  <td className={`whitespace-nowrap px-3 py-3 align-top text-sm ${material?.showAlternative ? 'text-black-500' : 'text-gray-500'
                                    }`}>
                                    {material?.manufacturer}

                                  </td>
                                  <td className={`whitespace-nowrap px-3 py-3 align-top text-sm ${material?.showAlternative ? 'text-black-500' : 'text-gray-500'
                                    }`}>
                                    {material?.type}
                                  </td>


                                  {
                                   
                                    <td className="whitespace-nowrap px-3 py-3 align-top text-sm text-gray-500">
                                    <Button
                                      color={"primary"}
                                      label={"Show"}
                                      onClick={() =>
                                        setCommentsModalShown(material.id)
                                      }
                                    />
                                  </td>
                                  }
                                  





                                  {
                                    // (userRole===4&& material.user.id!=userData.id)
                                    !(userData.role===4)&&
                                    <td className="whitespace-nowrap px-3 py-3 align-top text-sm text-gray-500">
                                      {!material.product ? <div></div> : <Button
                                        color={"primary"}
                                        label={"View " + material.product.length}
                                        onClick={() => {
                                          setProductList(material.product)
                                          // setShowProduct( (userRole===4&& material.user.id!=userData.id))
                                          isSetProductList(true);
                                        }
                                        }
                                      />}
                                    </td>
                                  }


                                  <td
                                    className="whitespace-nowrap px-3 py-3 align-top text-sm text-gray-500"
                                    style={{ minWidth: 170 }}
                                  >
                                    <div className={styles.certificatesWrapper}>
                                      {material.allCertificates?.length ? (
                                        <Button
                                          color={"primary"}
                                          label={`View ${material.allCertificates?.length}`}
                                          onClick={() => {
                                            setMaterialCertificates(
                                              material.allCertificates
                                            );
                                            setManufactureName(
                                              material.manufacturer
                                            );

                                            setCertificateType(material.type);
                                            // setRoHSCertificatesID(material.id);
                                            setMaterialCertificatesName(
                                              material?.componentName
                                            );
                                            setMaterialCertificatesID(material.id);
                                            // setMaterialCertificatesID(material.id);

                                            setRequiredCertificates(
                                              material.requiredCertificates
                                            );
                                          }}
                                        />
                                      ) : (

                                        <>
                                          {/* <div>
                                          <span className="text-yellow-400">
                                            Certificate not found
                                          </span>
                                        </div> */}
                                          {/* <div>
                                          <button
                                            className={"flex items-center"}
                                            onClick={() =>
                                              setMaterialId(material.id)
                                            }
                                          >
                                            <PlusIcon
                                              height={16}
                                              width={16}
                                              className={"mr-2"}
                                            />
                                            Add new certificate
                                          </button>
                                        </div> */}
                                        </>
                                      )}
                                    </div>
                                
                                  </td>


                                </tr>


                              </>
                            );
                          })}
                      </tbody>
                    </table>
                    {/* <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                      <div className="flex-1 flex justify-between sm:hidden">
                        <button
                          disabled={currentPage === 1}
                          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                          onClick={() =>
                            setCurrentPage((currentPage) => currentPage - 1)
                          }
                        >
                          Previous
                        </button>
                        <button
                          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                          onClick={() =>
                            setCurrentPage((currentPage) => currentPage + 1)
                          }
                        >
                          Next
                        </button>
                      </div>
                      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                        <div>
                          <p className="text-sm text-gray-700">
                            Showing{" "}
                            <span className="font-medium">
                              {(currentPage - 1) * itemsPerPage + 1}
                            </span>{" "}
                            to{" "}
                            <span className="font-medium">
                              {currentPage !== pageCount
                            ? currentPage * itemsPerPage
                            : materials?.length}
                            </span>{" "}
                            of{" "}
                            <span className="font-medium">
                            </span>{" "}
                            results
                          </p>
                        </div>
                        <div>
                          <nav
                            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                            aria-label="Pagination"
                          >
                            <button
                              onClick={() =>
                                setCurrentPage((currentPage) => currentPage - 1)
                              }
                              disabled={currentPage === 1}
                              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                            >
                              <span className="sr-only">Previous</span>
                              <ChevronLeftIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </button>

                            {showPagesCount(pageCount, currentPage).map(
                          (elem, ind) => {
                            if (elem === 0) {
                              return (
                                <div
                                  key={elem}
                                  aria-current="page"
                                  className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700"
                                >
                                  ...
                                </div>
                              );
                            }
                            return (
                              <button
                                key={elem}
                                onClick={() => setCurrentPage(elem)}
                                aria-current="page"
                                className={
                                  elem === currentPage
                                    ? styles.activeBtn
                                    : styles.defaultBtn
                                }
                              >
                                {elem}
                              </button>
                            );
                          }
                        )}
                            
                        <button
                          disabled={pageCount === currentPage}
                          onClick={() =>
                            setCurrentPage((currentPage) => currentPage + 1)
                          }
                          className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                        >
                          <span className="sr-only">Next</span>
                          <ChevronRightIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </button>
                          </nav>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            {!!materialCertificatesID && (
              <CustomModal
                isShown={!!materialCertificatesID}
                setModalShown={() => setMaterialCertificatesID(false)}
              >
                <MaterialComponentsCertificatesList
                  collectionDate={rohsCollectionDate}
                  rohsApprove={rohsCertificateApprove}
                  rohsLocalPath={roHSCertificatesPath}
                  type={certificateType}
                  manufactureName={manufactureName}
                  componentName={materialCertificatesName}
                  requiredCertificates={requiredCertificates}
                  materialCertificatesID={materialCertificatesID}
                  allCertificates={materialCertificates}
                  closeModal={() => setMaterialCertificatesID(false)}
                />
              </CustomModal>
            )}


            <CustomModal
              setModalShown={() => setMaterialId(false)}
              isShown={!!materialId}
            >
              <AddCertificate
                materialId={materialId}
                mutate={mutate}
                closeModal={() => setMaterialId(false)}
              />
            </CustomModal>

            {isCommentsModalShown && (
              <CustomModal
                isShown={!!isCommentsModalShown}
                setModalShown={() => setCommentsModalShown(null)}
              >
                <MaterialComments id={isCommentsModalShown} />
              </CustomModal>
            )}
            {productList && (
              <CustomModal
                isShown={!!isProductList}
                setModalShown={() => setProductList(null)}
              >
                <MaterialComponents productIDs={productList} showProduct={showProduct} />
              </CustomModal>
            )}

            {/* update email manufacturer button text by admin */}



            {/* taking customer required certificate information */}

          </div>}

    </>
  );
}
