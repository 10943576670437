import React from "react";

import '../AddProduct.scss'
import AddProductProgressBar, {PROGRESS_STATUS} from ".././AddProductProgressBar";
import {FileUploader} from "react-drag-drop-files";
import {fileTypes} from "../../../api/constants";
import {ReactComponent as Upload} from "../../../static/assets/svg/upload.svg";
import {ReactComponent as Document} from "../../../static/assets/svg/document.svg";
import {toast} from "react-toastify";



export type parsedFileDataType = {
	name: string;
	filePath:string;
	materials: {
		componentName: string;
		critical: boolean;
		manufacturer: string;
		type: string;
		datasheetURL: string;
	}[]
};

type AddProduct2StepType = {
	file: File | null
	setFile: (file: File | null) => void
};

const AddProduct2Step = (props: AddProduct2StepType) => {
	const {file, setFile} = props


	return (
		<>
			<AddProductProgressBar
				firstStatus={PROGRESS_STATUS.done}
				secondStatus={PROGRESS_STATUS.active}
			/>

			<FileUploader
				handleChange={setFile}
				onSizeError={(err: string) => toast.error(err)}
				onTypeError={(err: string) => toast.error(err)}
				name="file"
				classes={'DragDropFiles'}
				types={fileTypes}
				maxSize={10}
				children={
					<div className='DragDropFiles__body'>
						<Upload className="DragDropFiles__icon"/>
						<div className='DragDropFiles__body__title'>Upload a file <span>or drag and drop</span></div>
						<div className='DragDropFiles__body__subtitle'>Excel, CSV, XLSX up to 10MB</div>
					</div>}
			/>

			{file
				? <div className="AddProduct__fileView">
					<div className="AddProduct__fileView__left">
						<Document className="AddProduct__fileView__icon"/>
						<div className="AddProduct__fileView__text">{file?.name}</div>
					</div>
					<div
						className="AddProduct__fileView__right"
						onClick={() => setFile(null)}
					>Remove
					</div>
				</div>
				: null}

		</>
	);
};

export default AddProduct2Step;
