import React, { useEffect } from "react";
import useUser from "../../api/SWR/useUser";
import { removeTokens} from "../../api/utils";

function SignOut() {
  const { mutate } = useUser();
  useEffect(() => {
    removeTokens();
    mutate(null, false);
  }, [mutate]);
  return <div />;
}

export default SignOut;
