export const selectCustomStyles = {
    input: (provided: any, state: any) => ({
        ...provided,
        border: "none !important",
        outline: "none !important",
        input: "border: none; outline:none",
        "& input:focus": {
            outline: "none !important",
            border: "none !important",
            boxShadow: "none !important",
        },
    }),
    menuPortal: (base: any) => ({
        ...base,
        zIndex: 9999,
      }),
};