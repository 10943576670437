import { axiosInstance } from "../axiosInterceptor";
import useSWR from "swr";
import { taskNames } from "../constants";
import { getAccessToken } from "../utils";

function useUser() {
  const { data, error, mutate } = useSWR(
    taskNames.checkToken,
    () =>
      axiosInstance
        .post<any>('/user/checkAccessTokenAndGetUser', null,
        {
            headers: {Authorization: `${getAccessToken()}`}
        })
        .then((r) => r.data),
    { revalidateOnMount: false, revalidateOnFocus: false }
  );
  return {
    data,
    error,
    mutate,
    isLoading: !error && !data,
  };
}

export default useUser;
