import React, { useState } from "react";
import { certificateAPI, materialAPI } from "../../../api/apiRequests";
import { Input } from "../../input/Input";
import { mutate } from "swr";

const EditMaterialField = ({
  currentValue,
  type,
  id,
  setRoHSCollectionDate
}: {
  currentValue: string | undefined;
  type: string;
  id: number;
  setRoHSCollectionDate: any;
}) => {
  const [value, setValue] = useState(currentValue || "");

  const handleBlur = async () => {    
    await materialAPI.updateMaterialValues(id, value, type).then(()=>{
      setRoHSCollectionDate(value);
    });
    };

  return (
    <Input
      type="date"
      value={value}      
      onChange={(e: any) => setValue(e.target.value)}
      onBlur={handleBlur}
    />
  );
};

export default EditMaterialField;
