import React, { useEffect, useState } from "react";

import "./ScrapperModal.scss";

import ReactJson from "react-json-view";
import _ from "lodash";
import Loader from "../loader/Loader";
import { scrappingUrl } from "../../api/constants";

const ScrapperList = () => {
  const [scrapperList, setScrapperList] = useState<{}>({});
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      let res = await fetch(`${scrappingUrl}/list`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      res = await res.json();
      if (!_.isObject(res)) res = JSON.parse(res);
      setScrapperList(res);
      setLoading(false);
    };

    fetchData().catch(console.error);
  }, []);
  if (loading) return <Loader />;
  return (
    <div className="ScrapperListModal">
      {!_.isEmpty(scrapperList) ? <ReactJson src={scrapperList} /> : "No data"}
    </div>
  );
};

export default ScrapperList;
