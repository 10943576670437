import React from "react";
import useProduct from "../../api/SWR/useProduct";
import EditProductModal from "./EditProductModal";

const EditProductModalContainer = ({
  productID,
  setProductToEdit,
  mutate,
}: {
  productID: number;
  setProductToEdit: any;
  mutate: any;
}) => {
  const { data: product, isLoading: productLoading } = useProduct(productID);
  if (!product || productLoading) {
    return null;
  }
  return (
    <EditProductModal
      product={product}
      setProductToEdit={setProductToEdit}
      mutate={mutate}
    />
  );
};

export default EditProductModalContainer;
