import React from 'react';
import styles from "./styles.module.scss";
import { Button } from "../button/Button";
type TermsAndConditionsT = {
  handleCancel: any,
  handleAccept: any
}
const TermsAndConditions = (props: TermsAndConditionsT) => {
  const {
    handleCancel, handleAccept
  } = props;
  return (
    // <div className="max-w-6xl mx-auto mt-8 p-6 bg-white rounded shadow overflow-scroll  h-96 max-h-screen ">
    <div className={styles.modalWrap}>
      <h1 className="text-2xl font-bold mb-4">General Terms and Conditions</h1>

      <p>
        <strong>1.Background and Purpose</strong>
      </p>
      <p className='p-4'>
        1.1 Certainli AB, orgnr. 559 336-2006 (the “<strong>Company</strong>"), is a software service company developing software
        solutions for automated collection and management of component compliance documentation. The Service
        Provider's software solutions are hereinafter referred to as the "<strong>Software</strong>".
      </p>
      <p className='p-4'>
        1.2 These general terms and conditions regulate the usage of the Software by a Customer who wishes to try
        the Software for free during a limited one month’s period (the "<strong>Freemium Period</strong>") and with a maximum of three to five components uploaded to the platform per user.
      </p>
      <p className='p-4'>
        1.3 Prior to the expiration, the Company will reach out to the Customer in order to evaluate and discuss
        their future cooperation, and, if agreed, enter into a full-scale license agreement regarding the
        continuous usage of the Software.
      </p>

      <p>
        <strong>2. Software Access</strong>
      </p>
      <p className='p-4'>
        The Software is not sold but provided to the Customer as a so-called "software as a service" in a subscription form. The Service Provider hereby grants to the Customer a non-exclusive, non-sublicensable and non-assignable right to access and use the Software solely for the Customer's own internal business operations in accordance with these terms and conditions.
      </p>

      {/* Add the remaining sections similarly */}
      <p>
        <strong>3. Representations</strong>
      </p>
      <p className='p-4'>
        3.1 The Service Provider represents to the Customer that:
        <ol className="list-disc pl-6">
          <li>It has all necessary authority to license the Software, and</li>
          <li>To the best of its knowledge, the Software does not infringe any intellectual
            property rights of any third party.</li>

        </ol>

      </p>
      <p className='p-4'>
        3.2 Further, the Service Provider shall hold the Customer harmless for damages imposed upon the Customer on the grounds that the Software or its use constitutes the infringement of a third party's intellectual property rights or other property. However, the Service Provider shall only be liable where the Customer informs the Service Provider of the claim or allegation without delay, and the Service Provider immediately acquires full control over the claim, and the Customer provides the Service Provider with advice, information, and other assistance which the Service Provider may reasonably request.
      </p>
      <p className='p-4'>
        3.3 The Service Provider shall, however, not be liable for claims based on: (i) the Software having been combined with, or incorporated into, another product or technology; (ii) the Software having been changed or used contrary to the Service Provider's instructions; or (iii) the infringement having been caused by the Customer's property or a change of the Software at the Customer's request.
      </p>
      <p className="mt-4">
        <strong>4. Rights to title and ownership - Service Provider</strong>
      </p>
      <p className='p-4'>
        The Service Provider owns and retains all right, title, and interest in or to the Software,
        including, but not limited to, any source code, copy right, trade secrets, design and trademarks,
        and all other technologies related thereto, including any and all algorithms or processes and
        derivatives, modifications, or improvements of or to any of the foregoing.
      </p>

      <p className="mt-4">
        <strong>5. Reverse Engineering etc.</strong>
      </p>
      <p>
        5.1 The Customer and its users, including consultants and any advisors, may not:
      </p>
      <ul className="list-disc pl-6">
        <li>sublicense or otherwise make the Service available to third parties;</li>
        <li>copy, attempt to discover any source code, method, algorithm or process of the Software, or otherwise...</li>
        <li>remove, hide or circumvent the Service Provider's trademark or copyright marks
          or notices embedded in the Software; or</li>
        <li>seek to circumvent licenses keys or other user restrictions in the Software.</li>
      </ul>

      <p className="mt-4">
        <strong>6. Service Provider´s obligations</strong>
      </p>
      <p>
        6.1 The Service Provider is obliged to provide access to the Software around the clock,
        except for scheduled downtime.
      </p>
      <p>
        6.2 Scheduled downtime will be announced in advance to the Customer by giving a one-week
        notice. The Service Provider must take reasonable steps to minimize the downtime and
        any disturbance that may accrue for the Customers.
      </p>
      <p>
        6.3 The Service Provider shall remedy any Service interruptions or defects (“<strong>Issues</strong>”) during
        Monday-Friday between 09:00 and 18.00 CET, less any public holidays in Sweden (the
        "<strong>Service Time</strong>").
      </p>
      <p>
        6.4 Reported Issues will get an error ticket in the management system and be grouped into the
        following categories:
      </p>
      <ul className="list-disc pl-6">
        <li>Critical: Severe impact on user operations. Downtime in the Service.</li>
        <li>High: Serious impact on user operations. Significantly reduced functionality in core
          areas of the Software.</li>
        <li>Medium: Impact on user operations. The user can operate the system without
          immediate hindrance, but with noticeably reduced efficiency/functionality.</li>
        <li>Low: Minor impact on user experience/friendliness. The user can operate the
          Software without any direct hindrance.</li>
      </ul>
      <p>
        6.5 Interruptions and defects shall be reported via e-mail to: rashdan@certain.li
      </p>
      <p className='p-5'>
        “<strong>Action Period</strong>” shall mean the maximum time period for the Service Provider to solve
        an issue during the Service Time. The Action Period will be calculated from when the
        issue is registered in the Service Provider’s management system and shall end when the
        Service Provider submits a report to the Customer declaring that the use of Service can be
        resumed. For the avoidance of doubt, registration in the management system can only be
        made during the Service Time.
      </p>

      <table className="w-full border-collapse">
        <thead>
          <tr style={{ backgroundColor: "#44546A", color: "#ffffff" }}>
            <th className="p-2 border w-36 text-left">Priority</th>
            <th className="p-2 border text-left">Start of problem
              analysis</th>
            <th className="p-2 border text-left">Objectives for
              action</th>
            <th className="p-2 border text-left">Action Period</th>
            {/* Add more headers as needed */}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="p-2 border">Critical</td>
            <td className="p-2 border">Immediately</td>
            <td className="p-2 border">Within 8 h</td>
            <td className="p-2 border">Issue to be resolved
              within 12 hours</td>
          </tr>
          <tr>
            <td className="p-2 border">High</td>
            <td className="p-2 border">Immediately</td>
            <td className="p-2 border">Within 16 h</td>
            <td className="p-2 border">Issue to be resolved
              within 3 days</td>
          </tr>
          <tr>
            <td className="p-2 border">Medium</td>
            <td className="p-2 border">Within 8 h</td>
            <td className="p-2 border">Within 8 days</td>
            <td className="p-2 border">Issue to be resolved
              within 12 days</td>
          </tr>
          <tr>
            <td className="p-2 border">Low</td>
            <td className="p-2 border">No commitment</td>
            <td className="p-2 border">Within 60 days</td>
            <td className="p-2 border">No commitment</td>
          </tr>
          {/* Add more rows as needed */}
        </tbody>
      </table>



      <p className='mt-4'>
        <strong>7. Entire Agreement</strong>
      </p>
      <p className='p-4'>
        This Agreement represents the entire understanding between the Parties with respect to the
        subject matter hereof and supersedes any previous communication or agreements that may
        exist.
      </p>


      <p className='mt-4'>
        <strong>8. Severability</strong>
      </p>
      <p className='p-4'>
        In case any one or more of the provisions contained in this Agreement shall for any reason be
        held to be invalid, illegal or unenforceable in any respect, such invalidity, illegality or
        unenforceability shall not affect any other provisions of this Agreement, but this Agreement
        shall be construed as if such provision had never been contained herein. Upon such
        determination, the court, or other tribunal making such determination, is authorized and
        instructed to modify this Agreement so as to effect the original intent of the parties as closely
        as possible so that the intentions and agreements contemplated herein are consummated as
        originally contemplated to the fullest extent possible.
      </p>

      <p className='mt-4'>
        <strong>9. Governing law and disputes </strong>
      </p>
      <p className='p-4'>
        This Agreement is governed by and construed in accordance with Swedish law. Any dispute,
        controversy or claim arising out of or in connection with this Agreement, or the breach,
        termination, or invalidity thereof, shall be finally settled by Arbitration in accordance with the
        Rules of the Arbitration Institute of the Stockholm Chamber of Commerce in force at any time.
        The arbitral tribunal shall be composed of three (3) arbitrators. The place of arbitration shall be
        Stockholm, Sweden. The language to be used in the arbitration proceedings shall be English,
        unless otherwise agreed between the disputing parties. All arbitral proceedings shall be kept
        strictly confidential.
      </p>
      <div className="flex justify-center mt-4 ">
        {/* <button
          // onClick={handleCancel}
          className="bg-gray-500 text-white px-4 py-1 rounded hover:bg-gray-700 focus:outline-none focus:shadow-outline-gray mx-2"
          onClick={handleCancel}
        >
          Cancel
        </button>  */}
        {/* <Button
       color='#343541'
            label="Cancel"
        
          /> */}
        <Button
          label="Accept"
          onClick={handleAccept}
        />
      </div>
    </div>

  );
};

export default TermsAndConditions;
