import React, { useState } from "react";
import userAvatar from "../../static/assets/svg/userIcon.svg";
import { Button } from "../button/Button";
import { Input } from "../input/Input";
import editImage from "../../static/assets/svg/edit.svg"
import { fetcher } from "../../api/SWR/fetchers";
import { toast } from "react-toastify";
const UserProfileSetting = (props) => {

    const [userCollection, setUserCollection] = useState({
        firstName:props?.user?.firstName,
        lastName:props?.user?.lastName,
        userImg:props?.user?.profileImage,
        rawImage:''
    })
   const userProfileOnChange=(e)=>{
    const {name, value} = e.target
    setUserCollection({...userCollection,[name]:value})
   }
   const handleUploadClick = () => {
    const fileInput = document.getElementById('image-input');
    fileInput.click();
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const imageUrl = URL.createObjectURL(file);
    setUserCollection({...userCollection,userImg:imageUrl,rawImage:file})
    props.userImg(imageUrl)
  };
  const updateUser=async ()=>{
   const formData =new FormData()
   formData.append('firstName',userCollection.firstName);
   formData.append('lastName',userCollection.lastName);
   formData.append('id',props?.user?.id)
   if (userCollection.rawImage) {
       formData.append('profileImage',userCollection.rawImage)
   }
  let response=  await fetcher({
   key:'user/update',
   data:formData
  })
  if (response.affected>0) {
    toast.success("User updated successfully.")
  }
  props.modelClose()
  props.mutate()
  }
  return (
    <div className="py-5 px-3 ">
      {/* image circle */}
      <div className="flex flex-col justify-center items-center mb-5">
        <div className="relative w-52 h-52 mb-8 rounded-full border-2 border-gray-300 justify-center flex items-center"
      >
          <img src={userCollection.userImg ? userCollection.userImg:userAvatar} style={{
            width: userCollection.userImg ?"100%":100,
            height:userCollection.userImg ? "100%":100,
            borderRadius: userCollection.userImg ?'50%':'',
          }} alt="user" />
        <div 
        onClick={()=>handleUploadClick()}
        className="absolute top-40 right-6 bg-gray-300 p-3 rounded-full hover: cursor-pointer">
            <img
            width={20}
            src={editImage} alt="edit" />
             <input
        id="image-input"
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        style={{ display: 'none' }}
      />
        </div>
        </div>
        <div className="w-96 flex flex-col gap-5">
          <Input
          name="firstName"
          label="First Name"
          value={userCollection.firstName}
          onChange={userProfileOnChange}
          />
          <Input
          name="lastName"
          label="Last Name" 
          value={userCollection.lastName}
          onChange={userProfileOnChange}
          />
         
        </div>
      </div>
      <div className="flex justify-end">
        <Button label="Update"
        onClick={updateUser}
        />
      </div>
    </div>
  );
};

export default UserProfileSetting;
