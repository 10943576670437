import React from "react";
import "./SelectList.scss";
import classNames from "classnames";
import { INPUT_SIZE } from "../input/Input";
import Select from "react-select";
import { ReactComponent as MultiSelect } from "../../static/assets/svg/multiSelect.svg";
import { ReactComponent as Check } from "../../static/assets/svg/check.svg";
import { ReactComponent as SelectMenu } from "../../static/assets/svg/selectMenu.svg";

export type SelectOption = {
  value: string | number;
  label: string;
};
export type SelectProps = {
  label?: string;
  options?: SelectOption[];
  disabled?: boolean;
  className?: string;
  selectHeight?: string;
  isSearchable?: boolean;
  placeholder?: string;
  onChange?: (e: any) => void;
  isMulti?: boolean;
  value?: any;
  dropDownHeight?:number;
  index?:number;
};

const colourStyles = {

  placeholder: (defaultStyles: any) => {
    return {
      ...defaultStyles,
      fontFamily: "Circular-std-light",
      color: "#9CA3AF",
      lineHeight: 1,
      whiteSpace: "nowrap",
    };
  },
  control: (base: any) => ({
    ...base,
    border: "1px solid #D1D5DB",
    borderRadius: "6px",
    
    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
    height: 35,
    minHeight: 38,
    textAlign: 'left', 
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: "#808080", // Set the color to the same as the placeholder
  }),
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 9999,
    color: "#808080",
  }),
};

const CustomOption = (props: any) => {
  const { data, isSelected, innerRef, innerProps } = props;
  return (
    <div className="SelectList__option" ref={innerRef} {...innerProps}>
      {data?.label}
      {isSelected ? <Check className="SelectList__option__icon" /> : null}
    </div>
  );
};





const MultiSelectList = (props: SelectProps) => {
  const {
    label,
    selectHeight,
    options,
    onChange,
    isSearchable = false,
    className,
    placeholder = "",
    isMulti = false,
    value,
    dropDownHeight,
  } = props;

  return (
    <div className="SelectList">
      <label htmlFor="location" className="SelectList__label">
        {label}
      </label>
      <Select
        isSearchable={isSearchable}
        onChange={onChange}
        className={classNames(
          `SelectList--${selectHeight || INPUT_SIZE.small}`,
          className
        )}
        components={{
          IndicatorSeparator: () => null,
          MultiValueContainer: ({ selectProps, data }: any) => {
            const values = selectProps.value;
            if (values) {
              return values[values.length - 1].label === data.label
                ? data.label
                : data.label + ", ";
            } else return "";
          },
          DropdownIndicator: (props, context) =>
            props.isMulti ? (
              <MultiSelect className="SelectList__multiIcon" />
            ) : (
              <SelectMenu className="SelectList__multiIcon" />
            ),
          Option: CustomOption,
        }}
        
        placeholder={placeholder}
        styles={colourStyles}
        options={options}
        isMulti={isMulti}
        maxMenuHeight={dropDownHeight?dropDownHeight:500}

        closeMenuOnSelect={!isMulti}
        hideSelectedOptions={false}
        isClearable={false}
        value={value}
        menuPortalTarget={document.body}
      />
    </div>
  );
};

export default MultiSelectList;
