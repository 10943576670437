import React, { useEffect, useRef, useState } from "react";
import { Textarea } from "../textarea/Textarea";
import { Button } from "../button/Button";
import { materialAPI } from "../../api/apiRequests";
import classNames from "classnames";
import styles from "./styles.module.scss";
import useComments from "../../api/SWR/useComments";
import Loader from "../loader/Loader";
import useLockedBody from "../../hooks/useLockedBody";
import ScrollableFeed from "react-scrollable-feed";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import editImage from "../../static/assets/svg/edit.svg"
type MaterialCommentsPropsTypes = {
  id: number;
};

const avatarSrc =
  "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80";

const MaterialComments = ({ id }: MaterialCommentsPropsTypes) => {
  const [comment, setComment] = useState("");
  const [locked, setLocked] = useLockedBody();
  useEffect(() => {
    setLocked(true);

    return () => setLocked(false);
  }, []);

  const { data, isLoading, mutate } = useComments(id);

  const onSendCommentHandler = async (id: number, body: string) => {
    if(!body.trim()){
      toast.error("Write some thing")
      return;
    }
    const res = await materialAPI.saveComment(id, body);
    setComment("");
    await mutate();
  };

  const renderComments = () => {
    if (data?.length < 1) {
      return;
    }
    return (
      <div className={styles.commentsContainer}>
        <ScrollableFeed
          animateScroll={(element, offset) => {
            if (element.scrollBy) {
              element.scrollBy({ top: offset, behavior: "smooth" });
            } else {
              element.scrollTop = offset;
            }
          }}
        >
          {data.map((comment: any, reviewIdx: number) => (
            <div
              key={comment.id}
              className="flex space-x-4 text-sm text-gray-500"
            >
              <div className="flex-none py-10">
                <img
                
                  src={(comment?.author?.profileImage!=""&&comment?.author?.profileImage!=null)?comment?.author?.profileImage:editImage}
                  
                  alt="edit"
                  className="h-10 w-10 rounded-full bg-gray-100"
                  
                />
              </div>
              <div
                className={classNames(
                  reviewIdx === 0 ? "" : "border-t border-gray-200 w-full",
                  "py-10"
                )}
              >
                <h3 className="font-medium text-primary-500">
                  {`${comment.author?.firstName} ${comment.author?.lastName}`}
                </h3>
                <p className={"w-full"}>
                  <time
                    dateTime={dayjs(comment.createdAt).format(
                      "YYYY-MM-DD HH:mm"
                    )}
                  >
                    {dayjs(comment.createdAt).format("YYYY-MM-DD HH:mm")}
                  </time>
                </p>

                <div
                  className="prose prose-sm mt-4 max-w-none text-gray-500"
                  dangerouslySetInnerHTML={{ __html: comment.body }}
                />
              </div>
            </div>
          ))}
        </ScrollableFeed>
      </div>
    );
  };

  return (
    <div className={styles.wrapper}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div>
            <h3 className="text-4xl leading-10 font-medium mb-2.5 text-primary-500">
              {data?.length > 0
                ? "Comments"
                : "No comments added for this component yet"}
            </h3>
            {!isLoading && renderComments()}
          </div>
          <h3 className="text-2xl leading-10 font-medium mb-2.5 text-primary-500">
            Add new comment
          </h3>
          <div className={"flex flex-col"}>
            <Textarea
              placeholder={"Comment text"}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <div className={"ml-auto mt-4"}>
              <Button
                color="primary"
                label={"Send"}
                onClick={() => onSendCommentHandler(id, comment)}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MaterialComments;
