import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Button } from "../../../button/Button";
import { materialAPI, productApi } from "../../../../api/apiRequests";
import { toast } from "react-toastify";
import doneIcon from "../../../../static/assets/png/done.png";
import {
  getSpecificTemporaryCertificates,
  getTemporaryCertificates,
  getTemporaryCertificatesByMaterial,
  scrappingFetcher, vdeChecker
  // vdeSpecificCertificate
} from "../../../../api/SWR/fetchers";
import Loader from "../../../loader/Loader";
import { Input } from "../../../input/Input";
import { Toast } from "react-toastify/dist/components";

const AddCheckbox = ({
  onChange,
  value,
}: {
  value: boolean;
  onChange: any;
}) => {
  return (
    <input
      type="checkbox"
      checked={value}
      onChange={(e) => {
        e.stopPropagation();
        onChange();
      }}
      className="text-green-600 focus:ring-green-600"
    />
  );
};

const tableHeadings = [
  { heading: "Add", title: "id" },
  { heading: "Service name", title: "serviceName" },
  { heading: "Match Score", title: "matchPercent" },
  { heading: "AI Score", title: "aiScore" },
  { heading: "Manufacturer", title: "manufacturedPlant" },
  { heading: "Type", title: "type" },
  { heading: "Found", title: "found" },
  { heading: "REFERENCE NUMBER", title: "referenceNumber" },
  { heading: "SOURCE LINK", title: "sourceLink" },
];

const TempCertificatesTable = ({
  ranProducts,
  productID,
  updatedRanProduct,
  variations,
  fullModelName,
  materialId,
  materialComment,
  modalDummyNames,
  totalTempCertificates,
  manufacture,
  type,
  mutate,
  closeModal,
  modalNames,
  wsCertificates,
}: {
  ranProducts: any;
  productID: any;
  variations: any;
  fullModelName: any;
  materialId: any;
  manufacture: string;
  materialComment: string;
  type: string;
  modalDummyNames: any;
  totalTempCertificates: number;
  mutate: any;
  closeModal: () => void;
  modalNames: any[];
  wsCertificates: any;
  updatedRanProduct: any
}) => {
  const [expandedRowsId, setExpandedRowsId] = useState<number[]>([]);
  const [addedTempCertificatesIDs, setAddedTempCertificatesIDs] = useState<
    number[]
  >([]);
  const [order, setOrder] = useState("DSC");
  const [isLoading, setIsLoading] = useState(false);
  const [apiLoading, setApiLoading] = useState(false)
  const [tempCertificates, setTempCertificates] = useState<any>([]);
  const [skip, setSkip] = useState<number>(0);
  const [filterCertificates, setFilterCertificates] = useState<any>({});
  const [getSearchResults, setModelNames] = useState<any>([]);
  const [getLoadedCerts, setGetLoadedCerts] = useState<any>([]);
  const [manualManufacture, setManualManufacture] = useState<string>("");
  const [manualModelName, setManualModelName] = useState<string>("");
  const [commentSection, setCommentSection] = useState<string>("");
  const [variationsValue, setVariationState] = useState<any>([]);
  const [moreValue, setMoreValues] = useState<any>({});
  // const [webLoading, setWebLoading] = useState(false);
  const [userInput, setUserInput] = useState("");



  useEffect(() => {
    moreStatusCheck();
    if (variations) setVariationState(JSON.parse(variations));
    getTempCertificates(materialId, 20, skip);
  }, []);


  useEffect(() => {
    console.log(materialComment, "message is coming =====>");
    materialAPI.getMaterial(materialId).then((val: any) => {
      console.log("data updated", val);
      setCommentSection(val.materialComment);
    });
  }, [])

  //   useEffect(()=> {
  //     //here you will have correct value in userInput 
  // },[commentSection])


  const moreStatusCheck = () => {
    let data: any = {};
    modalNames.map((model: string) => {
      data[`${manufacture} ${model}`] = -1;
      data[model] = -1;
    });
    setMoreValues(data);
  };

  //get temp cert by material id
  const getTempCertificates = (
    materialId: number,
    takee: number,
    skip: number,
    data?: any,
    manufactureName?: string,
    modelName?: string
  ) => {

    setIsLoading(true);
    if (manufactureName || modelName) {
      if (!manufactureName) {
        manufacture = "no";
      }
      if (!modelName) {
        modelName = "no";
      }
      getSpecificTemporaryCertificates(
        materialId,
        takee,
        skip,
        manufacture,
        modelName
      )
        .then((res: any) => {
          if (res.tempCertificates.length === 0) {
            moreValue[
              getBaseValue({ model: modelName, manufacture: manufactureName })
            ] = 0;
            setMoreValues(moreValue);
            if (manufactureName === "no") {
              manufactureName = "";
            }
            if (modelName === "no") {
              modelName = "";
            }
            getLoadedCerts.push({
              model: modelName,
              manufacture: manufactureName,
            });

            setGetLoadedCerts(getLoadedCerts)
          }

          const sorted = ASDSorting("matchPercent", res.tempCertificates);
          setTempCertificates(sorted);
          setSkip(skip + 20);
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    } else {
      setApiLoading(true)
      let body = {
        modelList: modalDummyNames,
        manufacture: manufacture,
        variations: !variations ? [] : JSON.parse(variations)
      };
      // getTemporaryCertificates(materialId, takee, skip)
      getTemporaryCertificatesByMaterial(materialId, body)
        .then((res: any) => {
          console.log(res, "items");

          const sorted = ASDSorting("matchPercent", res.tempCertificates);
          console.log(sorted, "sorted items");

          setTempCertificates(sorted);
          setSkip(skip + 20);
          setApiLoading(false)
          setIsLoading(false);
        })
        .catch((e) => {
          setApiLoading(false)
          setIsLoading(false);
        });
    }
  };

  const handleManualVariation = async (id: number, value: string) => {
    const res = await productApi.updateMaterialCounter(id, value);
    mutate();
  };



  const ASDSorting = (col: any, data: any) => {
    var a = [...tempCertificates, ...data];
    const sorted = [...a].sort((a, b) => {
      return a[col] < b[col] ? 1 : -1;
    });
    return sorted;
  };
  const sortingRecord = (col: any) => {
    setIsLoading(false);

    if (order === "ASC") {
      const sorted =
        tempCertificates &&
        [...tempCertificates].sort((a, b) => {
          switch (col) {
            case "found":
              let modelNameA = JSON.parse(a["modelName"]);
              let modelNameB = JSON.parse(b["modelName"]);
              return modelNameA[col].toLowerCase() >
                modelNameB[col].toLowerCase()
                ? 1
                : -1;
            case "serviceName":
              return a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1;
            case "manufacturedPlant":
              let RawDataA = JSON.parse(a["rawData"]);
              let RawDataB = JSON.parse(b["rawData"]);
              return RawDataA[col].toLowerCase() > RawDataB[col].toLowerCase()
                ? 1
                : -1;
            case "id":
              return a[col] > b[col] ? 1 : -1;
            default:
              return a[col] > b[col] ? 1 : -1;
          }
        });
      setTempCertificates(sorted);
      setOrder("DSC");
    } else {
      const sorted =
        tempCertificates &&
        [...tempCertificates].sort((a, b) => {
          switch (col) {
            case "found":
              let modelNameA = JSON.parse(a["modelName"]);
              let modelNameB = JSON.parse(b["modelName"]);
              return modelNameA[col].toLowerCase() <
                modelNameB[col].toLowerCase()
                ? 1
                : -1;
            case "serviceName":
              return a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1;
            case "manufacturedPlant":
              let RawDataA = JSON.parse(a["rawData"]);
              let RawDataB = JSON.parse(b["rawData"]);
              return RawDataA[col].toLowerCase() < RawDataB[col].toLowerCase()
                ? 1
                : -1;
            case "id":
              return a[col] < b[col] ? 1 : -1;
            case "matchPercent":
              return a[col] < b[col] ? 1 : -1;
            default:
              return a[col] < b[col] ? 1 : -1;
          }
        });
      setTempCertificates(sorted);
      setOrder("ASC");
    }
  };
  const rowClickHandler = (id: number) => {
    if (expandedRowsId.indexOf(id) >= 0) {
      return setExpandedRowsId(expandedRowsId.filter((item) => item !== id));
    }

    setExpandedRowsId((prevState) => [...prevState, id]);
  };

  const addedTempCertificatesIDHandler = (id: number) => {
    if (addedTempCertificatesIDs.indexOf(id) >= 0) {
      return setAddedTempCertificatesIDs(
        addedTempCertificatesIDs.filter((item) => item !== id)
      );
    }

    setAddedTempCertificatesIDs((prevState) => [...prevState, id]);
  };

  const getManufactureName = (ManufactureName: string) => {
    const parsed = JSON.parse(ManufactureName);
    return parsed.manufacturedPlant;
  };

  const isManufactureFound = (ManufactureName: any) => {
    const parsed = ManufactureName;
    let manufactureLower = parsed.manufacture;
    return manufactureLower;
  };

  const getSearchResult = (data: any) => {
    const parsed = JSON.parse(data.rawData);
    const manufacture = data.manufacture;
    let modelName = parsed.cert.hasOwnProperty("cutModelName")
      ? parsed.cert.cutModelName.trim()
      : "";

    if (modelName != null) {
      modelName = modelName
        .replaceAll("%20", " ")
        .replaceAll("%0A", " ")
        .replaceAll("%2F", "/").trim();

      let isManufacture = modelName.includes(manufacture);
      let search = modelName;
      if (isManufacture) {
        search = modelName;
      } else {
        search = `${manufacture} ${modelName}`;
      }

      return search.trim();
    }
  };

  const getBaseValue = ({ model = "", manufacture = "" }) => {
    let search = `${manufacture} ${model}`;
    return search && search.trim();
  };

  const getFound = (modelName: string) => {
    const parsed = JSON.parse(modelName);
    return parsed.found;
  };
  const showRawData = (rewData: string) => {
    const parsed = JSON.parse(rewData);
    return (
      <ul className={styles.rowDataWrap}>
        {Object.keys(parsed).map((item: any, index) => {
          return (
            <li
              className={styles.modelView}
              key={`${item.materialId} + ${index}`}
            >
              {item} : {typeof parsed[item] === "string" ? parsed[item] : null}
              {""}
            </li>
          );
        })}
      </ul>
    );
  };

  const showAIScoreAlgoData = (data: string) => {
    // const parsed = JSON.parse(rewData);
    if (data)
      return (
        <ul className={styles.rowDataWrap}>
          {Object.keys(data).map((item: any, index) => {
            return (
              <li
                className={styles.modelView}
              // key={`${item.materialId} + ${index}`}
              >
                {item} : {typeof data[item] === "string" ? data[item] : null}
                {""}
              </li>
            );
          })}
        </ul>
      );
  };

  const addTempCertificatesHandler = async () => {
    try {
      setIsLoading(true);
      await materialAPI.addTempCertificatesIds(addedTempCertificatesIDs);
      await mutate();
      toast.success("Certificates added!");
      closeModal();
    } catch (e) {
      setIsLoading(false);
      toast.error("Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  const filteringCertificates = (match: string) => {

    let filterCerts = tempCertificates.filter((material: any) => {
      return match && match.trim() === `${getSearchResult(material)}`;
    });

    // return filterCerts;
    return elimateDuplicates(filterCerts);
  };



  const elimateDuplicates = (certs: any) => {
    const uniqueIds = new Set();
    let data = certs.filter((item: any, index: number) => {
      if (!uniqueIds.has(item.id)) {
        uniqueIds.add(item.id);
        return true;
      }
      return false;
    });

    return data;
  };
  const exceptFilterData = () => {
    return tempCertificates.filter((material: any) => {
      return (
        `${manufacture}` != `${getSearchResult(material)}` &&
        `${manufacture} ${modalNames[0]}` != `${getSearchResult(material)}` &&
        `${modalNames[0]}` != `${getSearchResult(material)}`
      );
    });
  };

  const handleRanProductsStart = async (product: any) => {
    let data;
    if (ranProducts) {
      data = JSON.parse(ranProducts);
      let isFind = false;
      for (const item of data) {
        if (
          item.model === product.model &&
          item.manufacture === product.manufacture
        ) {
          isFind = true;
          break;
        }
      }

      if (!isFind) {
        data.push({
          model: product.model,
          manufacture: product.manufacture,
          isRunning: true,
        });
        data = JSON.stringify(data);
      } else {
        let index = data.findIndex(
          (item: any) =>
            item.model === product.model &&
            item.manufacture === product.manufacture
        );

        if (index !== -1) {
          data[index].isRunning = true;
          data = JSON.stringify(data);
        } else {
        }
      }
    } else {
      data = JSON.stringify([
        {
          model: product.model,
          manufacture: product.manufacture,
          isRunning: true,
        },
      ]);
    }

    await handleRanProducts(materialId, data);
  };

  const handleRanProducts = async (id: number, value: string) => {
    const res = await productApi.updateMaterialRanProducts(id, value);
    mutate();
  };

  const handleStartScraping = async ({
    modelName,
    manuFacture
  }: any) => {
    setIsLoading(true);
    toast.info("Scraping is started!");

    try {
      let obj = {
        materialId: materialId,
        search: !modelName ? "" : modelName,
        manufacturer: !manuFacture ? "" : manuFacture,
        manufactureCopy: manuFacture,
        type: type,
        fullModelName: !modelName ? "" : fullModelName,
        cutModelName: modelName,
        productId: productID,

      };
      console.log(obj, "temp OBJECT");

      await scrappingFetcher([obj]);
      await handleRanProductsStart({
        model: obj.search,
        manufacture: obj.manufacturer,
      });
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const runChecker = (manufacture: string, model: string) => {
    let message = false;
    if (ranProducts) {
      let data = JSON.parse(ranProducts);
      let isFind = data.some(
        (item: any) => {
          return item.model === model &&
            item.manufacture === manufacture &&
            item.isRunning === false
        }
      );
      message = isFind;
    }

    return message;
  };

  const runCheckerProduct = (manufacture: string, model: string) => {

    if (ranProducts) {
      let data = JSON.parse(ranProducts)
      let item = data.find(
        (item: any) =>
          item.model === model &&
          item.manufacture === manufacture &&
          item.isRunning === true
      );
      return item && `${item.manufacture} ${item.model}`.trim();
    }
    return "";
  };

  const handleManualStartScraping = async () => {
    if (!manualManufacture && !manualModelName) {
      toast.error("Please! Enter Manufacture or ModelName");
    } else {
      toast.success(
        `Searching Start = ${manualManufacture} ${manualModelName}`
      );
      let data;
      if (variations) {
        data = JSON.parse(variations);
        data.push({
          model: manualModelName,
          manufacture: manualManufacture,
        });
        data = JSON.stringify(data);
      } else {
        data = JSON.stringify([
          {
            model: manualModelName,
            manufacture: manualManufacture,
          },
        ]);
      }
      handleManualVariation(materialId, data).then(() => {
        handleStartScraping({
          modelName: manualModelName,
          manuFacture: manualManufacture,
        });
      });
      setManualManufacture("");
      setManualModelName("");
    }
  };

  const modelExtractor = (key: string) => {
    key = key.replaceAll(manufacture, "");
    return key.trim();
  };

  const tempCertsFinder = (manufacture: string, model: string) => {

    let data = tempCertificates.filter((material: any, index: any) => {
      return `${manufacture} ${model}`.trim() == `${getSearchResult(material)}`
        ? true
        : false;
    });
    return data.length;
  };


  const loadedCertsFinder = (manufacture: string, model: string) => {

    let isFind = getLoadedCerts.some((data: any, index: any) => {
      return `${manufacture} ${model}`.trim() === `${data.manufacture} ${data.model}`.trim();
    });

    return !isFind;
  };

  const getCertificateNumber = (rawData: any) => {
    const parsed = JSON.parse(rawData)
    return parsed.certificateNumber
  }
  const getSourceLink = (rawData: any) => {
    const parsed = JSON.parse(rawData)
    console.log(parsed)
    return parsed
  }
  const redirectToScraperWebSite = (parsed: any) => {
    if (parsed.serviceName === 'VDE') {
      console.log(parsed)

      setApiLoading(true)
      setIsLoading(true);
      const body = {
        certificateNo: parsed.certificateNumber,
        manufacturer: parsed.manufacturedPlant,
        model: parsed.product
      }
      // console.log(body,'body')
      // vdeSpecificCertificate(body).then((response: any) => {
      vdeChecker(body).then((response: any) => {
        if (response.result) {
          window.open(response.url, "_blank");
          setApiLoading(false)
          setIsLoading(false);
        } else {
          toast.error("Link not work");
          setApiLoading(false)
          setIsLoading(false);
        }
      });
    } else {
      window.open(parsed.sourceCert, '_blank')
    }

  }

  const WAIT_INTERVAL = 1000;

  const ENTER_KEY = 13;
  let timer: ReturnType<typeof setTimeout>;
  const handleComments = (e: any) => {

    // clearTimeout(timer);

    setCommentSection(e.target.value);
    console.log(e.target.value);
    triggerChange(e.target.value)
    // timer = setTimeout(triggerChange, WAIT_INTERVAL);

  }
  async function triggerChange(f: any) {
    const value = f;
    console.log(value, "data auto trigger");
    await materialAPI.updateMaterialValues(materialId, value, "materialComment").then((val: any) => {
      console.log("data updated", value);
    });
    await mutate();

  }
  function handleKeyDown(e: any) {

    if (e.keyCode === ENTER_KEY) {

      // triggerChange();


    }

  }
  return (
    <div className={styles.wrapper}>
      <div className={"flex justify-between"}>
        <h3 className="text-4xl leading-10 font-medium mb-2.5 text-primary-500">
          Temporary Certificates
        </h3>
        <Button
          label={"ADD CERTIFICATES"}
          disabled={addedTempCertificatesIDs.length <= 0 || isLoading}
          onClick={addTempCertificatesHandler}
        />
      </div>
      <div className="flex space-x-4 my-3">
        <Input
          type="text"
          // label="Manufacturer"
          id="manufacturer"
          name="manufacturer"
          value={manualManufacture}
          onChange={(e) => {
            setManualManufacture(e.target.value);
          }}
          placeholder="Enter Manufacturer"
        />
        <Input
          type="text"
          // label="Model Name"
          id="modelName"
          value={manualModelName}
          name="modelName"
          onChange={(e) => {
            setManualModelName(e.target.value);
          }}
          placeholder="Enter Model Name"
        />
        <Button
          key={"search"}
          // loading={isLoadingIndex === i}
          label="Search"
          onClick={() => handleManualStartScraping()}
        />

        <div style={{ flex: 1 }}></div>



        <Input
          type="text"
          // className={styles.commentView}
          value={commentSection}
          name="commentSection"
          onChange={handleComments}
          // onChange={

          //   (e) => {

          //   handleComments(e.target.value)
          //   console.log("coment ===> ", e.target.value)

          // }

          // }
          // onKeyDown={handleKeyDown}

          placeholder="Enter Comment"
        />
        {/* <input onChange={changeHandler} value={userInput}></input> */}


      </div>

      {apiLoading ? <Loader /> : <div className={styles.tableWrapper}>
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              {tableHeadings.map((table, index) => (
                <th
                  onClick={() => sortingRecord(table.title)}
                  key={index}
                  scope="col"
                  className="px-6 py-3 text-left text-xs leading-4 font-medium tracking-wider uppercase text-gray-500 cursor-pointer"
                >
                  {table.heading}
                </th>
              ))}
            </tr>
          </thead>
        </table>

        {/* Step 3 // Manufacture and Model Name variations */}
        {modalNames
          ? modalNames.slice(0).map((item: string, i: number) => {
            return (
              <>
                <div className="flex gap-4">
                  <h2 className="text-1xl leading-10 font-medium mb-2.5 text-primary-500 w-96">
                    {manufacture} {item}
                  </h2>

                  <Button
                    key={i + "button"}
                    // loading={`${manufacture} ${item}` ===
                    //   runCheckerProduct(
                    //     manufacture && manufacture.trim(),
                    //     item && item.trim()
                    //   )
                    // }
                    label="Start"
                    onClick={() =>
                      handleStartScraping({
                        modelName: item.trim(),
                        manuFacture: manufacture.trim(),
                      })
                    }
                  />
                  {runChecker(
                    manufacture && manufacture.trim(),
                    item && item.trim()
                  ) ? (
                    <img src={doneIcon} key={item} className="h-5" />
                  ) : (
                    <p></p>
                  )}
                </div>
                <table className="min-w-full divide-y divide-gray-300">
                  <tbody className="bg-white w-full">
                    {filteringCertificates(
                      `${manufacture} ${item.trim()}`
                    ).map((material: any, index: any) => {
                      let aiScoreData = material.aiScoreData ? JSON.parse(material.aiScoreData) : null;
                      return (
                        <>
                          <React.Fragment key={index}>
                            <tr
                              className={`${index % 2 === 0 ? undefined : "bg-gray-50"
                                } cursor-pointer`}
                            >
                              <td className="whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900 sm:pl-6">
                                <AddCheckbox
                                  value={
                                    addedTempCertificatesIDs.indexOf(
                                      material.id
                                    ) >= 0
                                  }
                                  onChange={() =>
                                    addedTempCertificatesIDHandler(
                                      material.id
                                    )
                                  }
                                />
                              </td>
                              <td
                                className="whitespace-nowrap px-6 py-3 align-top text-sm text-gray-500"
                                onClick={() => rowClickHandler(material.id)}
                              >
                                {material.serviceName}
                              </td>

                              <td
                                className="whitespace-nowrap px-6 py-3 align-top text-sm text-gray-500"
                                onClick={() => rowClickHandler(material.id)}
                              >
                                {material.matchPercent}
                              </td>

                              <td
                                className="whitespace-nowrap px-6 py-3 align-top text-sm text-gray-500"
                                onClick={() => rowClickHandler(material.id)}
                              >
                                {!aiScoreData ? "NO" : aiScoreData["score"]}
                              </td>
                              <td
                                className={styles.found}
                                onClick={() => rowClickHandler(material.id)}
                              >
                                {getManufactureName(material.rawData)}
                              </td>
                              <td
                                className={styles.found}
                                onClick={() => rowClickHandler(material.id)}
                              >
                                {material.type}
                              </td>
                              <td
                                className={styles.found}
                                onClick={() => rowClickHandler(material.id)}
                              >
                                {getFound(material.modelName)}
                              </td>
                              <td
                                className={styles.certificateNumber}
                                onClick={() => rowClickHandler(material.id)}
                              >
                                {getCertificateNumber(material.rawData)}
                              </td>
                              <td>
                                <Button
                                  key={index}
                                  label="LINK"
                                  onClick={() => { redirectToScraperWebSite(getSourceLink(material.rawData)) }}
                                />
                              </td>
                            </tr>

                            <tr
                              className={
                                index % 2 === 0 ? undefined : "bg-gray-50"
                              }
                            >
                              <td colSpan={5}>
                                <div className={styles.expandedRowWrap}>
                                  <div
                                    className={
                                      expandedRowsId.indexOf(material.id) >= 0
                                        ? styles.showRow
                                        : styles.hideRow
                                    }
                                  >
                                    {showRawData(material.rawData)}
                                    {showAIScoreAlgoData(aiScoreData)}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </React.Fragment>
                        </>
                      );
                    })}
                  </tbody>
                </table>
                {totalTempCertificates >
                  tempCertsFinder(manufacture, item.trim()) &&
                  tempCertsFinder(manufacture, item.trim()) >= 10 && loadedCertsFinder(manufacture, item.trim()) ? (
                  <div className="flex justify-center  mt-3 mb-3">
                    <Button
                      label="LOAD MORE"
                      loading={isLoading}
                      onClick={() =>
                        getTempCertificates(
                          materialId,
                          20,
                          skip,
                          null,
                          manufacture,
                          item.trim()
                        )
                      }
                    />
                  </div>
                ) : (
                  <p></p>
                )}
              </>
            );
          })
          : ""}
        {/* ONLY MODEL NAME */}

        {modalNames
          ? modalNames.slice(0).map((item: string, index: number) => {
            return (
              <>
                <div className="flex gap-4">
                  <td>
                    {" "}
                    <h2 className="text-1xl leading-10 font-medium mb-2.5 text-primary-500 w-96">
                      {item}
                    </h2>
                  </td>

                  <Button
                    key={index + "button"}
                    loading={
                      `${item}` === runCheckerProduct("", item && item.trim())
                    }
                    label="Start"
                    onClick={() =>
                      handleStartScraping({
                        modelName: item,
                        manuFacture: ""
                      })
                    }
                  />
                  {runChecker("", item.trim()) ? (
                    <img src={doneIcon} className="h-5" key={item} />
                  ) : (
                    <p></p>
                  )}
                </div>
                <table className="min-w-full divide-y divide-gray-300">
                  <tbody className="bg-white w-full">
                    {filteringCertificates(`${item}`).map(
                      (material: any, i: any) => {
                        let aiScoreData = material.aiScoreData ? JSON.parse(material.aiScoreData) : null;
                        return (
                          <>
                            <React.Fragment key={index}>
                              <tr
                                className={`${index % 2 === 0 ? undefined : "bg-gray-50"
                                  } cursor-pointer`}
                              >
                                <td className="whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  <AddCheckbox
                                    value={
                                      addedTempCertificatesIDs.indexOf(
                                        material.id
                                      ) >= 0
                                    }
                                    onChange={() =>
                                      addedTempCertificatesIDHandler(
                                        material.id
                                      )
                                    }
                                  />
                                </td>
                                <td
                                  className="whitespace-nowrap px-6 py-3 align-top text-sm text-gray-500"
                                  onClick={() => rowClickHandler(material.id)}
                                >
                                  {material.serviceName}
                                </td>

                                <td
                                  className="whitespace-nowrap px-6 py-3 align-top text-sm text-gray-500"
                                  onClick={() => rowClickHandler(material.id)}
                                >
                                  {material.matchPercent}
                                </td>
                                <td
                                  className="whitespace-nowrap px-6 py-3 align-top text-sm text-gray-500"
                                  onClick={() => rowClickHandler(material.id)}
                                >
                                  {!aiScoreData ? "NO" : aiScoreData["score"]}
                                </td>
                                <td
                                  className={styles.manufacture}
                                  onClick={() => rowClickHandler(material.id)}
                                >
                                  {getManufactureName(material.rawData)}
                                </td>
                                <td
                                  className={styles.found}
                                  onClick={() => rowClickHandler(material.id)}
                                >
                                  {material.type}
                                </td>
                                <td
                                  className={styles.found}
                                  onClick={() => rowClickHandler(material.id)}
                                >
                                  {getFound(material.modelName)}
                                </td>
                                <td
                                  className={styles.certificateNumber}
                                  onClick={() => rowClickHandler(material.id)}
                                >
                                  {getCertificateNumber(material.rawData)}
                                </td>
                                <td>
                                  <Button
                                    key={index}
                                    label="LINK"
                                    onClick={() => { redirectToScraperWebSite(getSourceLink(material.rawData)) }}
                                  />
                                </td>
                              </tr>

                              <tr
                                className={
                                  index % 2 === 0 ? undefined : "bg-gray-50"
                                }
                              >
                                <td colSpan={5}>
                                  <div className={styles.expandedRowWrap}>
                                    <div
                                      className={
                                        expandedRowsId.indexOf(material.id) >=
                                          0
                                          ? styles.showRow
                                          : styles.hideRow
                                      }
                                    >
                                      {showRawData(material.rawData)}
                                      {showAIScoreAlgoData(aiScoreData)}

                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </React.Fragment>
                          </>
                        );
                      }
                    )}
                  </tbody>
                </table>
                {totalTempCertificates >
                  filteringCertificates(`${item}`).length &&
                  filteringCertificates(`${item}`).length >= 10 && loadedCertsFinder("", item.trim()) ? (
                  <div className="flex justify-center  mt-3 mb-3">
                    <Button
                      label="LOAD MORE"
                      loading={isLoading}
                      onClick={() =>
                        getTempCertificates(
                          materialId,
                          20,
                          skip,
                          null,
                          "",
                          item.trim()
                        )
                      }
                    />
                    {/* {moreValue[
                        getBaseValue({
                          model: item.trim(),
                          manufacture: manufacture,
                        })
                      ] === 0 ? (
                        <p></p>
                      ) : (
                    
                      )} */}
                  </div>
                ) : (
                  <p></p>
                )}
              </>
            );
          })
          : ""}
        {/* ONLY MANUFACTURE NAME */}
        {manufacture.length == 0 ? <div></div> : <>
          <div className="flex gap-4">
            <td>
              {" "}
              <h2 className="text-1xl leading-10 font-medium mb-2.5 text-primary-500 w-96">
                {/* {item.replace(/[-]/g, "")} */}
                {manufacture}
              </h2>
            </td>

            <Button
              // key={manufacture}
              loading={
                `${manufacture}` ===
                runCheckerProduct(manufacture && manufacture.trim(), "")
              }
              label="Start"
              onClick={() =>
                handleStartScraping({
                  modelName: "",
                  manuFacture: manufacture
                })
              }
            />
            {runChecker(manufacture, "") ? (
              <img src={doneIcon} className="h-5" />
            ) : (
              <p></p>
            )}
          </div>
          <table className="min-w-full divide-y divide-gray-300">
            <tbody className="bg-white w-full">
              {filteringCertificates(`${manufacture}`).map(
                (material: any, index: any) => {
                  let aiScoreData = material.aiScoreData ? JSON.parse(material.aiScoreData) : null;

                  return (
                    <>
                      <React.Fragment key={index}>
                        <tr
                          className={`${index % 2 === 0 ? undefined : "bg-gray-50"
                            } cursor-pointer`}
                        >
                          <td className="whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900 sm:pl-6">
                            <AddCheckbox
                              value={
                                addedTempCertificatesIDs.indexOf(material.id) >=
                                0
                              }
                              onChange={() =>
                                addedTempCertificatesIDHandler(material.id)
                              }
                            />
                          </td>
                          <td
                            className="whitespace-nowrap px-6 py-3 align-top text-sm text-gray-500"
                            onClick={() => rowClickHandler(material.id)}
                          >
                            {material.serviceName}
                          </td>

                          <td
                            className="whitespace-nowrap px-6 py-3 align-top text-sm text-gray-500"
                            onClick={() => rowClickHandler(material.id)}
                          >
                            {material.matchPercent}
                          </td>
                          <td
                            className="whitespace-nowrap px-6 py-3 align-top text-sm text-gray-500"
                            onClick={() => rowClickHandler(material.id)}
                          >
                            {!aiScoreData ? "NO" : aiScoreData["score"]}
                          </td>
                          <td
                            className="whitespace-nowrap px-6 py-3 align-top text-sm text-gray-500"
                            onClick={() => rowClickHandler(material.id)}
                          >
                            {getManufactureName(material.rawData)}
                          </td>
                          <td
                            className={styles.found}
                            onClick={() => rowClickHandler(material.id)}
                          >
                            {material.type}
                          </td>
                          <td
                            className={styles.found}
                            onClick={() => rowClickHandler(material.id)}
                          >
                            {getFound(material.modelName)}
                          </td>
                          <td
                            className={styles.certificateNumber}
                            onClick={() => rowClickHandler(material.id)}
                          >
                            {getCertificateNumber(material.rawData)}
                          </td>
                          <td>
                            <Button
                              key={index}
                              label="LINK"
                              onClick={() => { redirectToScraperWebSite(getSourceLink(material.rawData)) }}
                            />
                          </td>
                        </tr>

                        <tr
                          className={index % 2 === 0 ? undefined : "bg-gray-50"}
                        >
                          <td colSpan={5}>
                            <div className={styles.expandedRowWrap}>
                              <div
                                className={
                                  expandedRowsId.indexOf(material.id) >= 0
                                    ? styles.showRow
                                    : styles.hideRow
                                }
                              >
                                {showRawData(material.rawData)}
                                {showAIScoreAlgoData(aiScoreData)}

                              </div>
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    </>
                  );
                }
              )}
            </tbody>
          </table>
          {totalTempCertificates >
            filteringCertificates(`${manufacture}`).length &&
            filteringCertificates(`${manufacture}`).length >= 10 && loadedCertsFinder(manufacture, "") ? (
            <div className="flex justify-center  mt-3 mb-3">
              <Button
                label="LOAD MORE"
                loading={isLoading}
                onClick={() =>
                  getTempCertificates(
                    materialId,
                    20,
                    skip,
                    null,
                    manufacture,
                    ""
                  )
                }
              />
            </div>
          ) : (
            ""
          )}
        </>}

        <p className="text-1xl leading-10 font-bold mb-2.5 text-primary-500 w-96">
          Manual Variations:
        </p>
        {variationsValue
          ? variationsValue.slice(0).map((item: any, index: number) => {
            return (
              <>
                <div className="flex gap-4">
                  <td>
                    {" "}
                    <h2 className="text-1xl leading-10 font-medium mb-2.5 text-primary-500 w-96">
                      {/* {item.replace(/[-]/g, "")} */}
                      {item.manufacture} {item.model}
                    </h2>
                  </td>

                  <Button
                    key={index + "button"}
                    loading={
                      `${item.manufacture} ${item.model}`.trim() ===
                      runCheckerProduct(
                        item.manufacture && item.manufacture.trim(),
                        item.model && item.model.trim()
                      )
                    }
                    label="Start"
                    onClick={() =>
                      handleStartScraping({
                        modelName: item.model,
                        manuFacture: item.manufacture
                      })
                    }
                  />
                  {runChecker(item.manufacture.trim(), item.model.trim()) ? (
                    <img src={doneIcon} className="h-5" key={item} />
                  ) : (
                    <p></p>
                  )}
                </div>
                <table className="min-w-full divide-y divide-gray-300">
                  <tbody className="bg-white w-full">
                    {
                      filteringCertificates(
                        getBaseValue({
                          model: item.model,
                          manufacture: item.manufacture,
                        })
                      ).map((material: any, i: any) => {
                        let aiScoreData = material.aiScoreData ? JSON.parse(material.aiScoreData) : null;
                        return (
                          <>
                            <React.Fragment key={index}>
                              <tr
                                className={`${index % 2 === 0 ? undefined : "bg-gray-50"
                                  } cursor-pointer`}
                              >
                                <td className="whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  <AddCheckbox
                                    value={
                                      addedTempCertificatesIDs.indexOf(
                                        material.id
                                      ) >= 0
                                    }
                                    onChange={() =>
                                      addedTempCertificatesIDHandler(
                                        material.id
                                      )
                                    }
                                  />
                                </td>
                                <td
                                  className="whitespace-nowrap px-6 py-3 align-top text-sm text-gray-500"
                                  onClick={() => rowClickHandler(material.id)}
                                >
                                  {material.serviceName}
                                </td>

                                <td
                                  className="whitespace-nowrap px-6 py-3 align-top text-sm text-gray-500"
                                  onClick={() => rowClickHandler(material.id)}
                                >
                                  {material.matchPercent}
                                </td>
                                <td
                                  className="whitespace-nowrap px-6 py-3 align-top text-sm text-gray-500"
                                  onClick={() => rowClickHandler(material.id)}
                                >
                                  {!aiScoreData ? "NO" : aiScoreData["score"]}
                                </td>
                                <td
                                  className={styles.found}
                                  onClick={() => rowClickHandler(material.id)}
                                >
                                  {getManufactureName(material.rawData)}
                                </td>
                                <td
                                  className={styles.found}
                                  onClick={() => rowClickHandler(material.id)}
                                >
                                  {material.type}
                                </td>
                                <td
                                  className={styles.found}
                                  onClick={() => rowClickHandler(material.id)}
                                >
                                  {getFound(material.modelName)}
                                </td>
                                <td
                                  className={styles.certificateNumber}
                                  onClick={() => rowClickHandler(material.id)}
                                >
                                  {getCertificateNumber(material.rawData)}
                                </td>
                                <td>
                                  <Button
                                    key={index}
                                    label="LINK"
                                    onClick={() => { redirectToScraperWebSite(getSourceLink(material.rawData)) }}
                                  />
                                </td>
                              </tr>

                              <tr
                                className={
                                  index % 2 === 0 ? undefined : "bg-gray-50"
                                }
                              >
                                <td colSpan={5}>
                                  <div className={styles.expandedRowWrap}>
                                    <div
                                      className={
                                        expandedRowsId.indexOf(material.id) >= 0
                                          ? styles.showRow
                                          : styles.hideRow
                                      }
                                    >
                                      {showRawData(material.rawData)}
                                      {showAIScoreAlgoData(aiScoreData)}

                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </React.Fragment>
                          </>
                        );
                      })}
                  </tbody>
                </table>
                {
                  totalTempCertificates >
                  tempCertsFinder(item.manufacture, item.model.trim()) &&
                  tempCertsFinder(item.manufacture, item.model.trim()) >= 10 && loadedCertsFinder(item.manufacture, item.model.trim()) && (
                    <div className="flex justify-center  mt-3 mb-3">
                      {
                        <Button
                          label="LOAD MORE"
                          loading={isLoading}
                          onClick={() =>
                            getTempCertificates(
                              materialId,
                              20,
                              skip,
                              null,
                              item.manufacture.trim(),
                              item.model.trim()
                            )
                          }
                        />
                      }
                    </div>
                  )}
              </>
            );
          })
          : ""}
        <p className="text-1xl leading-10 font-bold mb-2.5 text-primary-500 w-96">
          Certificates Numbers:
        </p>
        <table className="min-w-full divide-y divide-gray-300">
          <tbody className="bg-white w-full">
            {
              tempCertificates.map((material: any, index: number) => {
                let aiScoreData = material.aiScoreData ? JSON.parse(material.aiScoreData) : null;
                return (
                  <>
                    {
                    material.certificateNumber &&
                      <React.Fragment key={index}>
                        <p className=" leading-10 font-bold mb-2.5 text-primary-500 w-10">
                          {material.certificateNumber}
                        </p>
                        <tr
                          className={`${index % 2 === 0 ? undefined : "bg-gray-50"
                            } cursor-pointer`}
                        >
                          <td className="whitespace-nowrap px-1 py-3 text-sm font-medium text-gray-900 sm:pl-6">
                            <AddCheckbox
                              value={
                                addedTempCertificatesIDs.indexOf(
                                  material.id
                                ) >= 0
                              }
                              onChange={() =>
                                addedTempCertificatesIDHandler(
                                  material.id
                                )
                              }
                            />
                          </td>
                          <td
                            className="whitespace-nowrap px-6 py-3 align-top text-sm text-gray-500"
                            onClick={() => rowClickHandler(material.id)}
                          >
                            {material.serviceName}
                          </td>

                          <td
                            className="whitespace-nowrap px-6 py-3 align-top text-sm text-gray-500"
                            onClick={() => rowClickHandler(material.id)}
                          >
                            {material.matchPercent}
                          </td>
                          <td
                            className="whitespace-nowrap px-6 py-3 align-top text-sm text-gray-500"
                            onClick={() => rowClickHandler(material.id)}
                          >
                            {!aiScoreData ? "NO" : aiScoreData["score"]}
                          </td>
                          <td
                            className={styles.manufacture}
                            onClick={() => rowClickHandler(material.id)}
                          >
                            {getManufactureName(material.rawData)}
                          </td>
                          <td
                            className={styles.found}
                            onClick={() => rowClickHandler(material.id)}
                          >
                            {material.type}
                          </td>
                          <td
                            className={styles.found}
                            onClick={() => rowClickHandler(material.id)}
                          >
                            {getFound(material.modelName)}
                          </td>
                          <td
                            className={styles.certificateNumber}
                            onClick={() => rowClickHandler(material.id)}
                          >
                            {/* {getCertificateNumber(material.rawData)} */}
                          </td>
                          <td>
                            <Button
                              key={index}
                              label="LINK"
                              onClick={() => { redirectToScraperWebSite(getSourceLink(material.rawData)) }}
                            />
                          </td>
                        </tr>

                        <tr
                          className={
                            index % 2 === 0 ? undefined : "bg-gray-50"
                          }
                        >
                          <td colSpan={5}>
                            <div className={styles.expandedRowWrap}>
                              <div
                                className={
                                  expandedRowsId.indexOf(material.id) >=
                                    0
                                    ? styles.showRow
                                    : styles.hideRow
                                }
                              >
                                {showRawData(material.rawData)}
                                {showAIScoreAlgoData(aiScoreData)}

                              </div>
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>}
                  </>
                );
              }
              )
            }
          </tbody>
        </table>
      </div>}
    </div>
  );
};

export default TempCertificatesTable;
// %20SPRING%20TERMINAL%202.5%20GREY%0APIT%202,5-Quattro%20Series
// SPRING TERMINAL 2.5 GREY PIT 2,5-Quattro Series
// SPRING TERMINAL 2.5 GREY%0APIT 2,5-Quattro Series

// %20PC%205628%2013%20T
// %20PC%205628%2013%20T

// SPRING TERMINAL 2.5 GREY PIT 2,5-Quattro Series
// %20SPRING%20TERMINAL%202.5%20GREY%0APIT%202,5-Quattro%20Series
