import React, { useCallback, useEffect, useState } from "react";
import ProductPage from "./ProductPage";
import { Redirect, useParams } from "react-router-dom";
import useProduct from "../../api/SWR/useProduct";
import Loader from "../../components/loader/Loader";
import useUser from "../../api/SWR/useUser";
import { ref } from "yup";

//@ts-ignore
window.wsDebug = [];

const ProductPageContainer = () => {
  const { data: userData } = useUser();
  const isAdmin = (userData.role === 1||userData.role===3);
  const { id } = useParams<{ id: string }>();
  const [wsCertificates, setWsCertificates] = useState<any[]>([]);
  const [isDetect, setIsDetect] = useState<any>(false);
  const { data, mutate, isLoading } = useProduct(+id);
  const [toggle, setToggle] = useState<boolean>(false)
  // Call the webSocketConnect only when we have the data
  useEffect(() => {
    if (data) {
      webSocketConnect();
    }
  }, [data]);

  // Here we have to use the useCallback, to get the fresh data.materials
  const webSocketConnect = useCallback(() => {
    // @ts-ignore
    let ws: any = new WebSocket(process.env.REACT_APP_WS);

    ws.onopen = () => {
      console.log("Connection opened!");
    };

    ws.onmessage = (event: any) => {
      const item = JSON.parse(event.data);
      // console.log("fining data here", item);
      if (item.event === "none") {
        setIsDetect(true);
        setWsCertificates([]);
      }

      if (
        item?.event &&
        item.event === "resultTempSearch" &&
        item?.data?.length
      ) {
        if (
          data?.materials
            ?.find((el: any) => el.id === item.materialId)
            ?.certificates.some((element: any) => element.id === item.id)
        ) {
          return;
        } else {
          //@ts-ignore
          window.wsDebug.push(item);
          // setIsDetect(false);
          setWsCertificates((prevState) => [...prevState, ...item.data]);
        }
      }
      if (item.event==='undefined') {
        setWsCertificates([])
      }
      if (item.event==='ranProductUpdate') {
        console.log('ran product event detect');
        
        if (data?.materials && data?.materials) {
         
          data.materials?.forEach((material:any)=>{
             if (material?.id==item?.data?.materialId) {
              material.ranProducts = item?.data?.ranProducts
              setToggle(!toggle)
             }
           })
           
          }
        }
      };
      setToggle(!toggle)

    // ws.onmessage = ({ data }: any) => {
    //   const item = JSON.parse(data);
    //   if (
    //     data.materials
    //       ?.find((el: any) => el.id === item.materialId)
    //       ?.certificates.some((element: any) => element.id === item.id)
    //   ) {
    //     return;
    //   } else {
    //     //@ts-ignore
    //     window.wsDebug.push(item);
    //     setWsCertificates((prevState) => [...prevState, item]);
    //   }
    // };
    ws.onerror = (e: any) => {
      console.log(
        "Socket is closed. Reconnect will be attempted in 1 second.",
        e.reason
      );
      setTimeout(() => {
        webSocketConnect();
      }, 1000);
    };
    ws.onclose = function () {
      ws = null;
    };
  }, [data]);

  const [scrapeListForSearch, setScrapeListForSearch] = useState([]);

  useEffect(() => {
    if (data) {      
      setScrapeListForSearch(
        data.materials.map((material: any) => ({
          fullModelName:material.componentName,
          materialId: material.id,
          search: material.componentName,
          manufacturer: material.manufacturer,
          manufactureCopy: material.manufacturer,          
          type: material.type,
          productId: data.id,
          datasheetURL:material.datasheetURL
        }))
      );
    }
  }, [data]);
  if (isLoading) return <Loader />;

  const statusId = data?.status?.id || 1;
  if (!isAdmin && statusId === 1) {
    return <Redirect to={"/products"} />;
  }
  if (!data) {
    return <Redirect to={"/products"} />;
  }

  return (
    <ProductPage
      mutate={mutate}
      product={data}
      scrapeListForSearch={scrapeListForSearch}
      wsCertificates={wsCertificates}
      isDetect={isDetect}
      setIsDetect={setIsDetect}
      setWsCertificates={setWsCertificates}
    />
  );
};

export default ProductPageContainer;
