import React, { useEffect } from "react";
import { Button, BUTTON_COLORS } from "../button/Button";
import Loader from "../../components/loader/Loader";
type ModalControlsType = {
  setModalIsOpen: (bool: boolean) => void;
  children: React.ReactNode;
  title: string;
  cancelText: string;
  cancelCallback: (defaultCancelCallback: () => void) => void;
  nextText?: string;
  nextCallback?: (defaultCancelCallback: () => void) => void;
  setStep: (step: number) => void;
  isNextButtonDisabled?: boolean;
};

const ModalContentWrapper = (props: ModalControlsType) => {
  const {
    setModalIsOpen,
    title,
    children,
    cancelText,
    cancelCallback,
    nextText,
    nextCallback,
    setStep,
    isNextButtonDisabled,
  } = props;
  const closeModal = () => setModalIsOpen(false);

  useEffect(() => {
    return () => setStep(1);
  }, []);
  return (
    <>
    {isNextButtonDisabled&&<Loader />}
      <div className="ReactModal__body">
        <div className="ReactModal__title">{title}</div>
        {children}
      </div>

      <div className="ReactModal__controls" style={{
        padding:'1rem 0.5rem 0.5rem 0.5rem'
      }}>
        <Button
          onClick={(() => cancelCallback(closeModal)) || closeModal}
          label={cancelText || "Cancel"}
          color={BUTTON_COLORS.normal}
        />
        {nextText && nextCallback ? (
          <Button
            onClick={() => nextCallback(closeModal)}
            label={nextText || "Next"}
            disabled={isNextButtonDisabled}
          />
        ) : null}
      </div>
    </>
  );
};

export default ModalContentWrapper;
