import React from "react";

import {useFormik} from "formik";
import * as Yup from "yup";
import {setAccessToken} from "../../../api/utils";

import useUser from "../../../api/SWR/useUser";
import {authApi} from "../../../api/apiRequests";
import {toast} from "react-toastify";
import {Input, INPUT_SIZE} from "../../../components/input/Input";
import {Button} from "../../../components/button/Button";
import './LoginForm.scss'
import {ReactComponent as Logo} from "../../../static/assets/svg/logo2.svg";
import RouterLink from "../../../components/link/RouterLink";
import {Checkbox} from "../../../components/checkbox/Checkbox";
import _ from "lodash";

const validationSchema = Yup.object().shape({
	email: Yup.string().email("Invalid email").required("Email is required"),
	password: Yup.string().required("password is required")
});

const LoginForm = () => {
	const {mutate} = useUser();
	const formik = useFormik({
		validationSchema: validationSchema,
		initialValues: {
			email: "",
			password: "",
			error: "",
		},
		validateOnChange: false,
		onSubmit: async (values, {setErrors}) => {
			try {
				const response = await authApi.login({
					email: values.email,
					password: values.password,
				});
				setAccessToken(response.data.accessToken);
				await mutate();
			} catch (e) {
				toast.error(_.get(e, 'response.data.message', "Error"));
			}
		},
	});

	return (
		<div className='LoginForm'>

			<RouterLink
				to={"/"}
				label=""
			>
				<Logo className="LoginForm__logo"/>
			</RouterLink>

			<div className="LoginForm__title">Sign in to your account </div>
			<div className="LoginForm__subTitle">Or
				<RouterLink
					to={"/"}
					label=" Create an account"
					className='LoginForm__subTitle__link'
				/>
			</div>

			<form onSubmit={formik.handleSubmit} className='LoginForm__form'>
				{formik?.errors?.error && (
					<div className="LoginForm__textError">{formik?.errors?.error}</div>
				)}
				<Input
					errorText={formik?.errors?.email}
					label="Email address"
					type="email"
					id="email"
					name="email"
					onChange={formik.handleChange}
					value={formik.values.email}
					inputHeight={INPUT_SIZE.large}
				/>
				<Input
					errorText={formik?.errors?.password}
					label="Password"
					type="password"
					id="password"
					name="password"
					onChange={formik.handleChange}
					value={formik.values.password}
					inputHeight={INPUT_SIZE.large}
				/>
				<div className="LoginForm__passwordRow">
					<Checkbox
						name='checkbox'
						options={[{value: 'checkbox', label: 'Remember me'}]}
						onChange={() => true}
					/>
					<RouterLink
						to={"/forget-password"}
						label="Forgot your password?"
						className='LoginForm__passwordRow__link'
					/>
				</div>
				<Button
					label="Sign in"
					className="LoginForm__button"
					type="submit"
				/>
			</form>
		</div>
	);
};

export default LoginForm;
