export const baseUrl = process.env.REACT_APP_API_URL + "/api";

export const scrappingUrl = process.env.REACT_APP_SCRAPPING_URL;

export const reactWebURL = process.env.REACT_APP_URL;
export const taskNames = {
  checkToken: "checkAccessTokenAndGetUser",
};

export const fileTypes = ["xlsx", "csv"];

export const receiverEmails = {
  receiver: "",
  ccEmails: ["alfred@certain.li","johan@certain.li"],
  // ccEmails: ["syedhamidali84@gmail.com",],
  // ccEmails: [],
};
export const defaultEmailText = `Dear Sir/Madam,\n
On behalf of firstName lastName, at myCompany, who is developing a product in which the component myComponent from you at myManufacture is considered as a critical component in an electrical safety testing, we are requesting component documentation for this. This includes listing numbers and/or test certificates for electrical/mechanical safety, etc., performed by approved Certification Bodies.\n
Thank you for your attention to this matter.
`;
