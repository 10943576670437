import { axiosInstance } from "../axiosInterceptor";
import useSWR from "swr";
import { getAccessToken } from "../utils";

function useAllComponents() {
    const { data, error, mutate } = useSWR("product/getAllComponents", () =>
      axiosInstance
        .post<any>("/product/getAllComponents", null, {
          headers: { Authorization: `${getAccessToken()}` },
        })
        .then((r) => r.data)
    );
    return {
      data,
      error,
      mutate,
      isLoading: !error && !data,
    };
  }

export default useAllComponents;
