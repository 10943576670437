import React from "react";
import LoginFormWrapper from "./login-form-wrapper/LoginFormWrapper";
import LoginForm from "./login-form/LoginForm";

const Login = () => {
  return (
    <LoginFormWrapper>
      <LoginForm />
    </LoginFormWrapper>
  );
};

export default Login;
