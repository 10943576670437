import React, { useEffect, useState } from "react";
import "./App.scss";
import {
  HashRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import RootLayout from "./pages/root-layout/RootLayout";
import Login from "./pages/login/Login";
import ProductsContainer from "./pages/products/ProductsContainer";
import ProductsContainerRohs from "./rohs/pages/products/ProductsContainer";
import { toast, ToastContainer } from "react-toastify";
import PrivateRoute from "./PrivateRoute";
import useUser from "./api/SWR/useUser";
import SignOut from "./pages/sign-out/SignOut";
import Loader from "./components/loader/Loader";
import NotFound from "./pages/not-found/NotFound";
import "react-toastify/dist/ReactToastify.css";
import ProductPageContainer from "./pages/product-page/ProductPageContainer";
import ComponentsContainer from "./pages/products/ComponentsContainer";
import ProductPageContainerRohs from "./rohs/pages/product-page/ProductPageContainer";
import ComponentsContainerRohs from "./rohs/pages/products/ComponentsContainer";
import ForgetForm from "./pages/forget-password/forget-form";
import ForgetPassword from "./pages/forget-password/forget-password";
import ResetPassword from "./pages/reset-password/reset-password";

function App() {
  const { mutate: getUser } = useUser();

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const authUser = async () => {
      if (localStorage.getItem("accessToken")) {
        try {
          await getUser();
        } catch (e) {
          toast.error(e?.response?.data || "Error");
        }
      }
      setIsAuthenticated(true);
    };

    authUser();
  }, [getUser]);

  if (!isAuthenticated) {
    return <Loader />;
  }

  return (
    <div className="App">
      <Router>
        <ToastContainer position="top-center" />
        <Switch>
          <PrivateRoute component={Login} path="/login" type="guest" />
          <PrivateRoute type="guest" path="/forget-password" component={ForgetPassword} />
          <PrivateRoute type="guest" path="/reset-password/:id" component={ResetPassword} />
          <RootLayout>
            <Switch>
              <PrivateRoute
                component={ProductsContainer}
                path="/products"
                type="user"
                exact
              />
              <PrivateRoute
                component={ComponentsContainer}
                path="/components"
                type="user"
                exact
              />
               <PrivateRoute
                type="user"
                component={ProductPageContainer}
                path={"/products/:id"}
              />

            
              {/* <PrivateRoute
                type="user"
                component={ProductPageContainer}
                path={"/products/:id"}
              /> */}
              
              <PrivateRoute type="user" path="/home" component={NotFound} />
              <PrivateRoute
                type="user"
                path="/certificates"
                component={NotFound}
              />
              {/* <PrivateRoute
                component={RohsRoutes}
                path="/rohs"
                type="user"
                exact
              /> */}
              <PrivateRoute type="user" path="/sign-out" component={SignOut} />
              <PrivateRoute type="user" path="/404" component={NotFound} />
              <Route path="/">
                <Redirect to="/products" />
              </Route>
            </Switch>
          </RootLayout>       
        </Switch>
      </Router>
    </div>
  );
}

export default App;
