import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import useUser from "./api/SWR/useUser";

type PrivateRouteType = {
  component: React.FC;
  path: string;
  type?: "user" | "guest";
};

const PrivateRoute = (props: PrivateRouteType & RouteProps) => {
  const { path, component: Component, location, type, ...rest } = props;

  const { data } = useUser();

  const isAdmin = data?.role;

  if (type === "guest" && isAdmin) {
    return <Redirect to="/" />;
  }

  if (type === "user" && !isAdmin) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location?.pathname },
        }}
      />
    );
  }

  return (
    <Route {...rest} render={(routeProps) => <Component {...routeProps} />} />
  );
};

export default PrivateRoute;
