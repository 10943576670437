import useSWR from "swr";
import { axiosInstance } from "../axiosInterceptor";
import { getAccessToken } from "../utils";

const UseFromFieldsData = () => {
  const { data, error, mutate } = useSWR("/standard-substitutions/all", () =>
    axiosInstance
      .post<any>("/standard-substitutions/all", null, {
        headers: { Authorization: `${getAccessToken()}` },
      })
      .then((r) => r.data)
  );
  return {
    data,
    error,
    mutate,
    isLoading: !error && !data,
  };
};

export default UseFromFieldsData;
