import React, {FC} from "react";
import './LoginFormWrapper.scss'
import loginBackground from '../../../static/assets/jpg/login-background.jpg';

const LoginFormWrapper: FC = ({children}) => {
	return (
		<div className="LoginFormWrapper">
			<div className="LoginFormWrapper__left">
				{children}
			</div>
			<div className="LoginFormWrapper__right">
				<img src={loginBackground}
				     alt="login-background"
				     className="LoginFormWrapper__right__image"
				/>
			</div>

		</div>
	);
};

export default LoginFormWrapper;
