import React, { useEffect, useLayoutEffect, useState } from "react";

import { ReactComponent as Logo } from "../../static/assets/svg/logo.svg";
import RouterLink from "../link/RouterLink";
import { Link, NavLink } from "react-router-dom";
import useUser from "../../api/SWR/useUser";
import "./Header.scss";
import avatarExample from "../../static/assets/svg/userIcon.svg";
import { Button } from "../button/Button";
import CustomModal from "../custom-modal/CustomModal";
import { Input } from "../input/Input";
import * as Yup from "yup";
import { useFormik } from "formik";
import { fetcher, getCompanies, getUsers, registerUser } from "../../api/SWR/fetchers";
import { Icons, toast } from "react-toastify";
import { LoadingIndicator } from "react-select/dist/declarations/src/components/indicators";
import Loader from "../loader/Loader";
import account from "../../static/assets/svg/account-settings.svg"
import powerOff from "../../static/assets/svg/power-off.svg"
import UserProfileSetting from "../account-setting/UserProfileSetting";
import _ from "lodash";
import { CustomStyle } from "../../constants/customStyle";
import Select from "react-select";
import { ROLES, ROLES_NAMES } from "../../constants/enums";
import EditCertificateField from "../ui/edit-certificate-field/EditCertificateField";
import EditUserField from "../ui/edit-certificate-field/EditUserField";
import { Checkbox } from "../checkbox/Checkbox";
import { certificateAPI } from "../../api/apiRequests";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Required"),
});
const companyValidationSchema = Yup.object().shape({
  companyName: Yup.string().required("Company name required"),
});

export type navItem = {
  label: string;
  path: string;
};

export type HeaderProps = {
  navItems: navItem[];
  dropItems: navItem[];
  title: string;
  mainPath?: string;
};
interface companyInterface {
  id: number;
  name: string;
  isSelected: boolean;
}
const Header = (props: HeaderProps) => {
  const { navItems, dropItems, title, mainPath } = props;
  const [userFormPopup, setUserFormPopup] = useState(false);
  const [allUserFormPopup, setAllUserFormPopup] = useState(false);
  const [companyFormPopup, setCompanyFormPopup] = useState(false);
  const [selectedRole, setSelectedRole] = useState(2);
  const [companies, setCompanies] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isRoHSSelected, setRoHSSelection] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<companyInterface>({
    id: 0,
    name: "",
    isSelected: true,
  });
  const [showDropdown, setShowDropdown] = useState(false)
  const [userProfilePopup, setUserProfilePopup] = useState(false)
  const { data, mutate } = useUser();
  const isAdmin = data?.role === 1;
  const isCompliance = data?.role === 3;
  const isRoHSAllowed = data?.isRoHSAllow;
  const [userProfile, setUserProfile] = useState('')
  const handelCompanyChange = (company: any) => {
    setSelectedCompany({
      ...selectedCompany,
      id: company.id,
      name: company.name,
    });
  };

  useEffect(() => {
      fetchCompanies();
  }, []);


  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if(isCompliance){
      setSelectedCompany({
        id: data.company.id,
        name: data.company.name,
        isSelected: true,
      })
    }
    
  }, [isCompliance]);
  useEffect(() => {
    if (data?.profileImage) {
      if (!data.profileImage.includes('http')) {
        data.profileImage = "https://" + data.profileImage
      }
      setUserProfile(data.profileImage)
    }
    else {
      setUserProfile(avatarExample)
    }
  }, [data])


  function fetchUsers() {
    setLoading(true);
    if(isCompliance){
      
      getUsers(data.company.id).then((res: any) => {
        const filteredUsers = res.filter((user: any) => user.email !== data.email);
        setAllUsers(filteredUsers);
        setLoading(false);
      });
    }else{
      getUsers().then((res: any) => {
        const filteredUsers = res.filter((user: any) => user.email !== data.email);
        setAllUsers(filteredUsers);
        setLoading(false);
      });
    }
    
  }

  function fetchCompanies() {

    setLoading(true);
    if(isCompliance){
      getCompanies(data.id,data.role).then((res: any) => {
        setCompanies(res);
        setLoading(false);
      });
    }else{
      getCompanies().then((res: any) => {
        setCompanies(res);
        setLoading(false);
      });
    }
    
  }
  const formik = useFormik({
    validateOnChange: true,
    validationSchema: validationSchema,
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      role: `${selectedRole}`
    },
    onSubmit: async (values, { setErrors }) => {

      const data: any = values;
      data.role = selectedRole;
      data.isRoHSAllow = isRoHSSelected;
      if (selectedRole != ROLES.DEVELOPER) {
        if (selectedCompany.id) {

          
          data.companyId = selectedCompany.id;


          console.log(data, "user data is here");
          registerUser(data).then((response: any) => {
            if (response?.data?.accessToken) {
              setUserFormPopup(false);
              formik.setValues({
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                role: `${selectedRole}`
              });
              setSelectedCompany({
                id: 0,
                name: "",
                isSelected: true,
              });
              toast.success("customer created");
            } else {
              toast.error(response?.data?.message);
            }
          });

        } else {
          toast.error("Select Company");
        }
      } else if (selectedRole == ROLES.DEVELOPER) {
        data.companyId = selectedCompany.id;
        registerUser(data).then((response: any) => {
          if (response?.data?.accessToken) {
            setUserFormPopup(false);
            formik.setValues({
              firstName: "",
              lastName: "",
              email: "",
              password: "",
              role: `${selectedRole}`
            });
            setSelectedCompany({
              id: 0,
              name: "",
              isSelected: true,
            });
            toast.success("customer created");
          } else {
            toast.error(response?.data?.message);
          }
        });
      }
      else {
        setSelectedCompany({ ...selectedCompany, isSelected: false });
      }
    },
  });
  const companyFormik = useFormik({
    validateOnChange: true,
    validationSchema: companyValidationSchema,
    initialValues: {
      companyName: "",
    },
    onSubmit: async (value, { setErrors }) => {
      let data = {
        key: "/company",
        data: {
          name: value.companyName,
        },
      };
      let response = await fetcher(data);

      if (response.result === true) {
        toast.success(response.message);
        companyFormik.setValues({ companyName: "" });
        setCompanyFormPopup(false);
        fetchCompanies();
      } else {
        toast.error(response.message);
      }
    },
  });

  const userRegistrationForm = () => {
    return (
      <div className="w-128 px-12 py-6">
        <form onSubmit={formik.handleSubmit}>
          <div className="flex gap-2 flex-col">
            <p className="Header__label">Select Role</p>

            <select
              className="Input Input__body"

              onChange={(e: any) => {
                console.log(e.target.value);

                setSelectedRole(e.target.value);
              }
              }
              name="componentStatus"
              id="component-status"
            >
              <option
                className="text-gray-300"
                value={ROLES.USER}
              >
                {ROLES_NAMES.USER}
              </option>
              <option
                value={ROLES.COMPLIANCE}

              >
                {ROLES_NAMES.COMPLIANCE}
              </option>
              <option
                value={ROLES.DEVELOPER}
              >
                {" "}
                {ROLES_NAMES.DEVELOPER}
              </option>
            </select>
            <Input
              type="text"
              label="First Name"
              name="firstName"
              onChange={formik.handleChange}
              value={formik?.values?.firstName}
              errorText={formik?.errors?.firstName}
            />
            <Input
              type="text"
              label="Last Name"
              name="lastName"
              onChange={formik?.handleChange}
              value={formik?.values?.lastName}
              errorText={formik?.errors?.lastName}
            />
            <Input
              type="text"
              label="Email"
              name="email"
              onChange={formik?.handleChange}
              value={formik?.values?.email}
              errorText={formik?.errors?.email}

            />
            <Input
              type="text"
              label="Password"
              name="password"
              onChange={formik?.handleChange}
              value={formik?.values?.password}
              errorText={formik?.errors?.password}

            />

            {
            // selectedRole == ROLES.DEVELOPER ? <div></div> :
             loading ? (
              <Loader />
            ) : (
              <>
                {companies && <div className="flex flex-col">
                  <p className="Header__label">Select Company</p>
                  <Select
                    placeholder={"Select company"}
                    options={
                      companies.map((option: { id: number; name: string }) => ({
                        value: option?.id,
                        label: option?.name,
                      }))
                    }
                    value={{ value: selectedCompany.id, label: selectedCompany.name }}
                    onChange={(company: any) => { setSelectedCompany({ ...selectedCompany, id: company?.value, name: company?.label }) }}
                    menuPortalTarget={document.body}
                    maxMenuHeight={220}
                    menuPlacement="auto"
                    styles={{
                      option: (provided) => ({
                        ...provided,
                        color: '#616161',
                        fontWeight: 'normal'
                      }),
                    }}
                  />
                </div>}
                {selectedCompany.isSelected === false ? (
                  <p className="text-red-500"> Please select a company</p>
                ) : (
                  ""
                )}
              </>
            )}

            {isRoHSAllowed && <div className="flex items-center gap-5">
              <input              
                className="text-green-600 focus:ring-green-600"
                type={"checkbox"}
                checked={isRoHSSelected}
                onChange={() => {
                  setRoHSSelection(!isRoHSSelected);
                }
                }
              />
              <p className="text-xs leading-4 font-medium ">RoHS Access</p>
            </div>}
          </div>
          <div className={"flex justify-end mt-4"}>
            <Button type={"submit"} label="Register" />
          </div>
        </form>
      </div>
    );
  };

  const renderTableInput = (
    value: string | undefined,
    type: string,
    id: number
  ) => {
    if (isEditMode) {
      return <EditUserField currentValue={value} type={type} id={id} />;
    } else return value;
  };

  const tableHeadings = [
    "First Name",
    "Last Name",
    "Email",
    "Role",
    "RoHS",
  ];
  function toggleEditMode() {
    setIsEditMode(!isEditMode)
    fetchUsers()
  }
  const allUsersPopup = () => {
    return (
      // overflow-y-scroll 
      <div className="w-328 px-12 py-6">
        <div className="flex p-2 justify-end min-w-full" >
          {
            !isAdmin?<></>:
            <Button color={isEditMode ? "primary" : "normal"} onClick={toggleEditMode} label={!isEditMode ? "Edit" : "Save"} />
          }
          </div>
       <div className="max-h-128 overflow-auto">
       <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>

              {tableHeadings.map((table, index) => (
                <th
                  key={index}
                  scope="col"
                  className="px-6 py-3 text-left text-xs leading-4 font-medium tracking-wider uppercase text-gray-500 cursor-pointer"
                >
                  {table}
                </th>
              ))}
            </tr>
          </thead>
          {/* overflow-y-scroll */}
          <tbody className="bg-white">
            {allUsers.map((user: any, index) => {
              return <tr
                className={`${index % 2 === 0 ? undefined : "bg-gray-50"
                  } cursor-pointer`}
              >
                <td
                  className="whitespace-nowrap px-6 py-3 align-top text-sm text-gray-500"
                // onClick={() => rowClickHandler(material.id)}
                >
                  {renderTableInput(
                    user.firstName,
                    "firstName",
                    user.id
                  )}
                  {/* {user.firstName} */}
                </td>

                <td
                  className="whitespace-nowrap px-6 py-3 align-top text-sm text-gray-500"
                // onClick={() => rowClickHandler(material.id)}
                >
                  {renderTableInput(
                    user.lastName,
                    "lastName",
                    user.id
                  )}

                </td>

                <td
                  className="whitespace-nowrap px-6 py-3 align-top text-sm text-gray-500"
                // onClick={() => rowClickHandler(material.id)}
                >
                  {renderTableInput(
                    user.email,
                    "email",
                    user.id
                  )}
                </td>
                <td
                  className="whitespace-nowrap px-6 py-3 align-top text-sm text-gray-500"
                // onClick={() => rowClickHandler(material.id)}
                >

                  <select
                    className="border border-gray-300 rounded text-gray-500"
                    defaultValue={user.role}
                    disabled={!isEditMode}
                    onChange={(event) => {                       
                      certificateAPI.updateUserValues(user.id, event.target.value, "role");
                    }

                    }
                  >
                       <option value={ROLES.ADMIN} className="text-gray-500">
                      {ROLES_NAMES.ADMIN}
                    </option>
                    <option value={ROLES.USER} className="text-gray-500">
                      {ROLES_NAMES.USER}
                    </option>
                    <option value={ROLES.COMPLIANCE} className="text-gray-500">
                      {ROLES_NAMES.COMPLIANCE}
                    </option>
                    <option value={ROLES.DEVELOPER} className="text-gray-500">
                      {ROLES_NAMES.DEVELOPER}
                    </option>
                  </select>

                </td>
                <td
                  className="whitespace-nowrap px-6 py-3 align-top text-sm text-gray-500"
                // onClick={() => rowClickHandler(material.id)}
                >
                  {console.log(user, "user log is here")}

                  <input type="checkbox" className="Checkbox__body"
                  disabled={!isEditMode} onChange={(e) => {
                    certificateAPI.updateUserValues(user.id, !user.isRoHSAllow, "isRoHSAllow");
                    fetchUsers();
                  }}
                    checked={user.isRoHSAllow}
                  />




                </td>
              </tr>
            })}
          </tbody>
        </table>
       </div>

      </div>
    );
  };
  //   create company popup
  const createCompany = () => {
    return (
      <div className="w-128 px-12 py-6">
        <form onSubmit={companyFormik.handleSubmit}>
          <div className="flex gap-2 flex-col">
            <Input
              type="text"
              label="Company Name"
              name="companyName"
              onChange={companyFormik.handleChange}
              value={companyFormik?.values?.companyName}
              errorText={companyFormik?.errors?.companyName}
            />
          </div>
          <div className={"flex justify-end mt-4"}>
            <Button type={"submit"} label="Create" />
          </div>
          <p className="mt-5 font-bold" style={{ color: CustomStyle.headingColor }}>
            Already created companies
          </p>
          <div
            className="flex flex-wrap rounded-md"
            style={{
              background: '#F3F4F6',
              maxHeight: 'calc(4 * 2.5rem)', // Adjust the value (4 rows) as needed
              overflow: 'scroll',
            }}
          >
            {companies &&
              companies.map((company: any) => (
                <p
                  key={company.id}
                  className="bg-white rounded-md shadow px-2 m-1">{company.name}</p>
              ))}
          </div>
        </form>
      </div>
    );
  };
  return (
    <div className="Header App__container">
      <RouterLink
        to={mainPath ? mainPath : "/"}
        label=""
        // underline={false}
        className="flex-shrink-0"
        data-test="Logo-link"
      >
        <Logo className="Header__logo" />
      </RouterLink>
      <div className="Header__navItems">
        {navItems.map((navItem) => (
          <NavLink
            key={`natItem_${navItem.label}`}
            exact={navItem.path === (mainPath ? mainPath : "/")}
            to={navItem.path}
            activeClassName="Header__navItem--active"
            data-test={`${navItem.label}-link`}
            className="Header__navItem"
          >
            {navItem.label}
          </NavLink>
        ))}
      </div>
      <div className="flex gap-3">
        {isAdmin && (
          <Button
            label="Create Company"
            onClick={() => setCompanyFormPopup(true)}
          />
        )}
        {( isCompliance || (isAdmin && companies.length > 0))  && (
          <Button
            label="Create User"
            onClick={() => {
              
              fetchCompanies();
              setUserFormPopup(true);
            }}
          />
        )}

        {(isCompliance||(isAdmin && allUsers.length > 0)) && (
          <Button
            label="Users"
            onClick={() => {
              fetchUsers();
              setAllUserFormPopup(true);
            }}
          />
        )}

      </div>
      {data?.company && (
        <div>
          <h4>{data.company.name}</h4>
        </div>
      )}
      {/* <Link to="/sign-out" className="Header__avatar"> */}
      <div className="relative">
        <img
          onClick={() => setShowDropdown(!showDropdown)}
          src={userProfile}
          alt="header avatar"
          className="Header__avatar__image hover: cursor-pointer border-2"
        />
        <div className={`absolute mt-2 py-2 w-48 right-2  flex flex-col gap-2 rounded-md shadow-lg  border-2 border-gray-300 bg-white ${showDropdown ? '' : 'hidden'}`}>
          <li className="list-none">
            <div
              onClick={() => {
                setUserProfilePopup(true)
                setShowDropdown(false)
              }}
              className="flex px-2 items-center justify-between hover:bg-gray-100 cursor-pointer">
              <img
                width={30}
                src={account}
                alt="user setting"
              />
              <p className="text-sm font-bold  text-gray-500">Account Setting</p>
            </div>
          </li>
          <li className="list-none">
            <Link to="/sign-out">
              <div className="flex px-2 justify-between hover:bg-gray-100 cursor-pointer">
                <img
                  width={25}
                  src={powerOff}
                  alt="logout"
                />
                <p className="text-sm font-bold  text-gray-500">Logout</p>
              </div></Link>
          </li>
        </div>
      </div>
      {/* </Link> */}
      <CustomModal
        isShown={userFormPopup}
        setModalShown={() => setUserFormPopup(false)}
      >
        {userRegistrationForm()}
      </CustomModal>
      <CustomModal
        isShown={allUserFormPopup}
        setModalShown={() => setAllUserFormPopup(false)}
      >
        {allUsersPopup()}
      </CustomModal>
      <CustomModal
        isShown={companyFormPopup}
        setModalShown={() => {
          setCompanyFormPopup(false);
          companyFormik.setValues({ companyName: "" });
        }}
      >
        {createCompany()}
      </CustomModal>
      <CustomModal
        isShown={userProfilePopup}
        setModalShown={() => setUserProfilePopup(false)}
      >
        {<UserProfileSetting user={data} userImg={(img: any) => setUserProfile(img)} modelClose={() => setUserProfilePopup(false)} mutate={mutate} />}
      </CustomModal>
    </div>
  );
};

export default Header;
