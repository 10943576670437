type getTokenType = () => string | null;

export const getAccessToken: getTokenType = () =>
  localStorage.getItem("accessToken");

export const setAccessToken = (token: string) =>
  localStorage.setItem("accessToken", token);

export const removeTokens = () => {
  localStorage.removeItem("accessToken");
};
