import { axiosInstance } from "../axiosInterceptor";
import useSWR from "swr";
import { getAccessToken } from "../utils";

function useComments(id: any) {
  const { data, error, mutate } = useSWR(
    [id, "/material/comment/getAll"],
    (id, route) =>
      axiosInstance
        .post<any>(
          route,
          { entityId: id },
          {
            headers: { Authorization: `${getAccessToken()}` },
          }
        )
        .then((r) => r.data)
  );
  return {
    data,
    error,
    mutate,
    isLoading: !error && !data,
  };
}

export default useComments;
