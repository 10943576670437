import React, { useState } from "react";
import { certificateAPI } from "../../../api/apiRequests";
import { Input } from "../../input/Input";

const EditCertificateField = ({
  currentValue,
  type,
  id,
}: {
  currentValue: string | undefined;
  type: string;
  id: number;
}) => {
  const [value, setValue] = useState(currentValue || "");

  const handleBlur = async () => {
    await certificateAPI.updateCertificateValues(id, value, type);
  };

  return (
    <Input
      type="text"
      value={value}
      onChange={(e: any) => setValue(e.target.value)}
      onBlur={handleBlur}
    />
  );
};

export default EditCertificateField;
