import LoginFormWrapper from "../login/login-form-wrapper/LoginFormWrapper";
import ResetForm from "./reset-form";

const ResetPassword = () => {
    return (
        <LoginFormWrapper>
        <ResetForm/>
      </LoginFormWrapper>
    );
  };
  
  export default ResetPassword;