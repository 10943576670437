import React, { useEffect, useRef, useState } from "react";
import { Textarea } from "../textarea/Textarea";
import { Button } from "../button/Button";
import { materialAPI } from "../../api/apiRequests";
import classNames from "classnames";
import styles from "./styles.module.scss";
import useComments from "../../api/SWR/useComments";
import Loader from "../loader/Loader";
import useLockedBody from "../../hooks/useLockedBody";
import ScrollableFeed from "react-scrollable-feed";
import dayjs from "dayjs";
import { reactWebURL } from "../../api/constants";
import { Link } from "react-router-dom";

type MaterialComponentsPropsTypes = {
  productIDs: [];
  showProduct:boolean
};
const avatarSrc =
  "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80";


const MaterialComponents = ({ productIDs,showProduct }: MaterialComponentsPropsTypes,) => {




  return (
    <div className={styles.wrapper}>

      <h3 className="text-2xl leading-10 font-medium mb-2.5 text-primary-500">
        Products
      </h3>

    <div className={styles.commentsContainer}>
      {
        productIDs.map((val: any, index) => {

          return <div>

            <div className={styles.linkStyle}>
              <h3 className="text-2l leading-10 font-medium mb-2.5 text-primary-500">
                {val.name}
              </h3>


              <Button
                key={index}
                label="Open"
                disabled={showProduct}
                onClick={() => {
                  window.open(`${window.location.origin + "/#/products/" + val.id}`, "_blank");
                }}
              />
            </div>


          </div>
        })
      }

    </div>


    </div>
  );
};

export default MaterialComponents;
