import React, { FunctionComponent } from "react";
import { Link, LinkProps } from "react-router-dom";
import classNames from "classnames";
import "./link.css";

export type RouterLinkProps = {
  label: string;
};

const RouterLink: FunctionComponent<LinkProps & RouterLinkProps> = (
  props: LinkProps & RouterLinkProps
) => {
  const { label, className, children, ...params } = props;

  return (
    <Link
      className={className}
      {...params}
    >
      {label}
      {children}
    </Link>
  );
};

export default RouterLink;
