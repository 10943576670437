import { Button } from "../../components/button/Button";
import { INPUT_SIZE, Input } from "../../components/input/Input";
import RouterLink from "../../components/link/RouterLink";
import { ReactComponent as Logo } from "../../static/assets/svg/logo2.svg";
import { useFormik } from "formik";
import useUser from "../../rohs/api/SWR/useUser";
import * as Yup from "yup";
import { Redirect, Router, useHistory, useParams, useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import { resetPasswordApi } from "../../api/apiRequests";
import _ from "lodash";


const validationSchema = Yup.object().shape({
	password: Yup.string().required("password is required"),
	confirm_password: Yup.string().required("Confirm password is required")
	
});
const ResetForm = () => {
	let history = useHistory();
	const { id } = useParams<{ id: string }>();
	


	const formik = useFormik({
		validationSchema: validationSchema,
		initialValues: {
			password: "",
			confirm_password: ""

		},
		validateOnChange: false,
		onSubmit: async (values, { setErrors }) => {

			
			
			console.log("id   is ==>", id)
			if (values.password != values.confirm_password) {
				toast.error("Password not match")
				return;
			}else if (values.password.length<8){
				toast.error("Password length must be greater than  eight character")
				return;
			}
			try {
				const response = await resetPasswordApi.resetApi({
					id: id,
					password: values.password
				});
				if (response.status == 201 && response.data) {
					values.confirm_password='';
					values.password='';
					toast.success(response.data.message);

					
					history.push("/")
					

				}
			} catch (e) {
				toast.error(_.get(e, 'response.data.message', "Error"));
			}
		},
	});

	return (
		<div className='LoginForm'>

			<RouterLink
				to={"/"}
				label=""
			>
				<Logo className="LoginForm__logo" />
			</RouterLink>

			<div className="LoginForm__title">Reset password </div>
			{/* <div className="LoginForm__subTitle">Or
				<RouterLink
					to={"/"}
					label=" Forget password"
					className='LoginForm__su1bTitle__link'
				/>
			</div> */}

			<form onSubmit={formik.handleSubmit} className='LoginForm__form'>
				{/* {formik?.errors?.error
             && (
					<div className="LoginForm__textError">{formik.errors.email}</div>
				)} */}

				<Input
					errorText={formik?.errors?.password}
					label="Password"
					type="password"
					id="password"
					name="password"
					onChange={formik.handleChange}
					value={formik.values.password}
					inputHeight={INPUT_SIZE.large}
				/>
				<Input
					errorText={formik?.errors?.confirm_password}
					label="Confirm Password"
					type="password"
					id="confirm_password"
					name="confirm_password"
					onChange={formik.handleChange}
					value={formik.values.confirm_password}
					inputHeight={INPUT_SIZE.large}
				/>
				<Button
					label="Confirm"
					className="LoginForm__button"
					type="submit"
				/>
			</form>
		</div>
	);
};
export default ResetForm;