import React, { useEffect } from "react";

import "./AddProduct.scss";
import AddProduct1Step from "./steps/AddProduct1Step";
import AddProduct2Step, { parsedFileDataType } from "./steps/AddProduct2Step";
import AddProduct3Step from "./steps/AddProduct3Step";
import { FormikProps } from "formik";
import UseFromFieldsData from "../../api/SWR/useFromFieldsData";
import Loader from "../loader/Loader";

type AddProductType = {
  step: number;
  formik: FormikProps<any>;
  file: File | null;
  setFile: (file: File | null) => void;
  parsedFileData: parsedFileDataType | null;
  setParsedFileData: any;
  suggestedCerts: any;
  requiredCerts: any;
};

const AddProduct = (props: AddProductType) => {
  const {
    step,
    formik,
    setFile,
    file,
    parsedFileData,
    setParsedFileData,
    suggestedCerts,
    requiredCerts,
  } = props;
  const { data: formData, isLoading } = UseFromFieldsData();
  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="AddProduct">
      {step === 1 ? (
        <AddProduct1Step formik={formik} formData={formData} />
      ) : null}
      {step === 2 ? <AddProduct2Step setFile={setFile} file={file} /> : null}
      {step === 3 ? (
        <AddProduct3Step
          parsedFileData={parsedFileData}
          setParsedFileData={setParsedFileData}
          suggestedCerts={suggestedCerts}
          requiredCerts={requiredCerts}
        />
      ) : null}
    </div>
  );
};

export default AddProduct;
