import { axiosInstance } from "../axiosInterceptor";
import useSWR from "swr";
import { getAccessToken } from "../utils";

function useProduct(id: string | number) {
  const { data, error, mutate } = useSWR(
    ["product/get", id],
    () =>
      axiosInstance
        .post<any>(
          "/product/get",
          { id },
          {
            headers: { Authorization: `${getAccessToken()}` },
          }
        )
        .then((r) => r.data),
    { revalidateOnFocus: false }
  );

  return {
    data,
    error,
    mutate,
    isLoading: !error && !data,
  };
}

export default useProduct;
