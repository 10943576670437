import React from "react";
import "./Checkbox.scss";

export type CheckboxProps = {
  type?: string;
  name: string;
  disabled?: boolean;
  options: { value: string; label: string }[];
  onChange(event: { value: string; checked: boolean }): void;
};

export function Checkbox(props: CheckboxProps) {
  const { name, options, onChange, disabled } = props;
  return (
    <fieldset className="Checkbox">
      {options.map((el, index) => (
        <div
          className="Checkbox__elem"
          key={`checkbox_elem_${el.value}_${index}`}
        >
          <input
            id={el.value}
            name={name}
            type={`${props.type ? props.type : 'checkbox'}`}
            value={el.value}
            disabled={disabled}
            className="Checkbox__body"
            onChange={({ target }) =>
              onChange({ value: target.value, checked: target.checked })
            }
          />
          <label htmlFor="push_everything" className="Checkbox__label">
            {el.label}
          </label>
        </div>
      ))}
    </fieldset>
  );
}
