import { axiosInstance } from "../axiosInterceptor";
import useSWR from "swr";
import { getAccessToken } from "../utils";

function useProducts() {
  const { data, error, mutate } = useSWR("product/getAll", () =>
    axiosInstance
      .post<any>("/product/getAll", null, {
        headers: { Authorization: `${getAccessToken()}` },
      })
      .then((r) => r.data)
  );
  return {
    data,
    error,
    mutate,
    isLoading: !error && !data,
  };
}

export default useProducts;
