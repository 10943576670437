import React, { useEffect, useState } from "react";
import { getTemporaryCertificates } from "../../../api/SWR/fetchers";
import { Button } from "../../button/Button";
import CustomModal from "../../custom-modal/CustomModal";
import Loader from "../../loader/Loader";
import TempCertificatesTable from "./temp-certificates-table/TempCertificatesTable";

type MatchScoreColumnPropsTypes = {
  variations: any;
  productID: any;
  fullModelName: any;
  totalTempCertificates: number;
  materialId: number;
  mutate: any;
  manufacture: string;
  ranProducts: string;
  type: string;
  materialComment: string;
  wsCertificates:any
};

const MatchScoreColumn = ({
  variations,
  productID,
  fullModelName,
  totalTempCertificates,
  materialId,
  manufacture,
  ranProducts,
  type,
  mutate,
  materialComment,
  wsCertificates,
}: MatchScoreColumnPropsTypes) => {
  const [isTempCertificatesModalShown, setIsTempCertificatesModalShown] =
    useState(false);
  const [tempCertificates, setTempCertificates] = useState<any>();
  const [getModelNames, setModelNames] = useState<any>([]);
  const [getDummyModelNames, setDummyModelNames] = useState<any>([]);
  const [getFullModelNames, setFullModelNames] = useState<string>("");
const [ranProductsState, setRanProductsState] = useState(ranProducts)
  const [take, setTake] = useState<number>(10);
  const [skip, setSkip] = useState<number>(0);
  //get temp cert by material id
  const getTempCertificates = (materialId: number) => {
    setIsTempCertificatesModalShown(true);
    getTemporaryCertificates(materialId, take, skip).then((res: any) => {
      setTempCertificates(res.tempCertificates);
    });
  };
  useEffect(() => {
    console.log(ranProducts, 'load ranProduct');
    
   setRanProductsState(ranProducts)
  }, [ranProducts])
  
const updateRanProducts=(product:any)=>{
setRanProductsState(product)
}
  const modalNamesGenerator = (modelName: string) => {

let dummy = [];
    for (let i = 0; i < modelName.length - 1; i++) {
      console.log(modelName[modelName.length - i], "my name");

      if (modelName.length >= 2) {
        console.log(modelName[modelName.length - i], "my name push");

        var modelNameLength = modelName.substring(0, modelName.length - i);
        if (
          modelNameLength[modelNameLength.length - 1] != "-" &&
          modelNameLength[modelNameLength.length - 1] != " " &&
          modelNameLength[modelNameLength.length - 1] != "+"
        ) {
          console.log(modelNameLength, "my name push two");
          dummy.push(modelNameLength);
        }
      }
    }
    setDummyModelNames(dummy);


  
    // .replaceAll("+", "");
    let data = [];
    for (let i = 0; i < modelName.length - 1; i++) {
      console.log(modelName[modelName.length - i], "my name");

      if (modelName.length >= 2) {
        console.log(modelName[modelName.length - i], "my name push");

        var modelNameLength = modelName.substring(0, modelName.length - i);
        if (
          modelNameLength[modelNameLength.length - 1] != "-" &&
          modelNameLength[modelNameLength.length - 1] != " " &&
          modelNameLength[modelNameLength.length - 1] != "+"
        ) {
          console.log(modelNameLength, "my name push two");
          data.push(modelNameLength);
        }
      }
    }



    setModelNames(data);
    setFullModelNames(fullModelName);
    setIsTempCertificatesModalShown(true);
  };

  const tempCertificatesCount = totalTempCertificates;
  return (
    <>
      <div className={"flex flex-col"}>
        {totalTempCertificates > 0 ? (
          ""
        ) : (
          <p className={"text-center"}>{totalTempCertificates || ""}</p>
        )}

        {
          <div>
            <Button
              label={`Show ${
                tempCertificatesCount ? tempCertificatesCount : ""
              }`}
              onClick={() => modalNamesGenerator(fullModelName)}
            />
          </div>
        }
      </div>
      <CustomModal
        setModalShown={() => setIsTempCertificatesModalShown(false)}
        isShown={isTempCertificatesModalShown}
      >
        <TempCertificatesTable
        productID={productID}
          updatedRanProduct =  {(item: any)=> updateRanProducts(item)}
          ranProducts={ranProductsState}
          manufacture={manufacture}
          type={type}
          variations={variations}
          fullModelName={getFullModelNames}
          materialId={materialId}
          totalTempCertificates={tempCertificatesCount}
          materialComment={materialComment}
          mutate={mutate}
          closeModal={() => setIsTempCertificatesModalShown(false)}
          modalNames={getModelNames}
          modalDummyNames={getDummyModelNames}
          wsCertificates={wsCertificates}
        />
      </CustomModal>
    </>
  );
};

export default MatchScoreColumn;
