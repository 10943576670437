import React from "react";
import "./Textarea.scss";

export function Textarea(props: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLTextAreaElement>,
	HTMLTextAreaElement> & {
	label?: string,
	value?: string
	placeholder?: string
}) {

	const {label, value, placeholder, ...params} = props

	return (
		<div className="Textarea">
			<div className="Textarea__label">{label}</div>
			<textarea
				{...params}
				placeholder={placeholder}
				value={value}/>
		</div>
	);
}
