import { axiosInstance, axiosScraperInstance } from "./axiosInterceptor";
import { fetcher, getRequiredCertificates } from "./SWR/fetchers";
import { getAccessToken } from "./utils";

type loginType = {
  email: string;
  password: string;
};

export const authApi = {
  login: ({ email, password }: loginType) =>
    axiosInstance.post<any>(`/user/login`, { email, password }),
};

export const productApi = {
  saveProduct: (
    id: number | undefined,
    name: string,
    type: {}[],
    standards: {},
    markets: {},
    description: string,
    developmentStatus: {},
    manufacturer: string,
    customer: string,
    filePath:string|undefined,
    collectionProductMaterials: {
      name: string;
    },
    materials: {
      componentName: string;
      critical: boolean;
      manufacturer: string;
      datasheetURL: string | undefined;
      isSuggestionSelected:boolean;
      type: string;
    }[],

  ) => {        
    return fetcher({
      key: "product/save",
      data: {
        id,
        name,
        type,
        standards,
        markets,
        description,
        developmentStatus,
        collectionProductMaterials,
        materials,
        manufacturer,
        customer,
        filePath
      },
    });
  },
  editProduct: (
    id: number,
    name: string,
    description: string,
    type: any,
    standards: number[],
    markets: number[],
    developmentStatus: any,
    manufacturer: string,
    customer: string
  ) => {
    return fetcher({
      key: "product/update",
      data: {
        id,
        name,
        description,
        type,
        standards,
        markets,
        developmentStatus,
        manufacturer,
        customer,
      },
    });
  },
  updateProductStatus:(productId:number, statusId:number)=>{
    return fetcher({
      key:"product/updateStatus",
      data:{
        productId:productId,
        statusId:statusId
      }
    })
  },
  deleteProduct:(productId:number)=>{
    return fetcher({
      key:"product/delete",
      data:{
        id:productId
      }
    })
  },
  updateMaterialCriticalStatus: (id: number, value: boolean) => {
    return fetcher({
      key: "material/update",
      data: {
        id,
        critical: value,
      },
    });
  },

  updateMaterialCounter: (id: number, value: string) => {
    return fetcher({
      key: "material/update",
      data: {
        id,
        variations: value,
      },
    });
  },

  updateMaterialRanProducts: (id: number, value: string) => {
    console.log(value, "item my");

    return fetcher({
      key: "material/update",
      data: {
        id,
        ranProducts: value,
      },
    });
  },
};


export const materialAPI = {
  saveComment: (id: number, body: string) => {
    return fetcher({
      key: "material/comment/save",
      data: { entityId: id, body },
    });
  },
  addTempCertificatesIds: (body: number[]) => {
    return axiosScraperInstance
      .post<any>(
        `/scrapers/material/confirm-certificates/`,
        { ids: body },
        {
          headers: {
            Authorization: `${getAccessToken()}`,
          },
        }
      )
      .then((r: any) => r.data);
  },
  saveMaterial: (body: any) => {
    console.log(body);

    return fetcher({
      key: "material/save",
      data: body,
    });
  },
  deleteMaterials: (id: number, materialIds: number[]) => {
    return fetcher({
      key: "material/delete",
      data: { productId: id, id: materialIds },
    });
  },
  getSuggestedCertificates: (componentNames: string[]) => {
    return fetcher({
      key: "material/get/certificates/by/component-names",
      data: { componentNames },
    });
  },
  getRequiredCertificates: () => {
    return getRequiredCertificates();
  },
  updateMaterialValues: (id: number, value: any, type: string) => {
    return fetcher({
      key: "material/update",
      data: {
        id,
        [type]: value,
      },
    });
  },

  getMaterial: (id: number) => {
    return fetcher({
      key: "material/get",
      data: {
        id     
      },
    });
  },
};

export const roHSAPI = {
  getFiles: (id: number) => {
    return axiosInstance.get("rohs/getRohsFiles/"+id)
  },

  deleteFile: (id)=>{
    return axiosInstance.post("rohs/deleteRohsFiles",{
      "ids":id
    } )
  }
}

export const certificateAPI = {
  delete: (id: number[]) => {
    return fetcher({
      key: "certificate/delete",
      data: { id: id },
    });
  },
  updateCertificateValues: (id: number, value: string, type: string) => {
    return fetcher({
      key: "certificate/update",
      data: {
        id,
        [type]: value,
      },
    });
  },
};
