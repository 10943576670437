import emailjs from '@emailjs/browser';
// product and component email notification
// export const EmailNotification=(data:any)=>{

//     const dataObj = {
//       email_subject: data.subject,
//       body_content:data.content,
//       cc_emails:data.cc_emails
//       };
//       emailjs.send('service_eqavmk8', 'template_d1vfx8m', dataObj, '1uOLUIKLyJgneP9rV').then((result)=>{
//         return result.text
//       })
       
// }
export const formateDate=(date:any)=>{
  const dateObject = new Date(date);
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, '0');
  const day = String(dateObject.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}