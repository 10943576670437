import React, { useState } from "react";
import styles from "./styles.module.scss";
import * as Yup from "yup";
import { useFormik } from "formik";
import { fetcher } from "../../api/SWR/fetchers";
import { toast } from "react-toastify";
import { Input } from "../input/Input";
import { FileUploader } from "react-drag-drop-files";
import { ReactComponent as Upload } from "../../static/assets/svg/upload.svg";
import { ReactComponent as Document } from "../../static/assets/svg/document.svg";
import { Button } from "../button/Button";

const validationSchema = Yup.object().shape({
  type: Yup.string().required("Required"),
  // rawVersion: Yup.string().required("Required"),
  models: Yup.string().required("Required"),
  dateIssue: Yup.string().required("Required"),
  dateCollection: Yup.string().required("Required"),
  // referenceNumber: Yup.string().required("Required"),
  // expireDate: Yup.string().required("Required"),
});

const AddRoHS = ({
  materialId,
  mutate,
  closeModal,
}: {
  materialId: number | boolean;
  mutate: any;
  closeModal: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState<any>([]);


  const formik = useFormik({
    validateOnChange: true,
    validationSchema: validationSchema,
    initialValues: {
      type: "",
      models: "",
      dateIssue: "",
      dateCollection: new Date().toISOString().slice(0, 10),
      materialID: materialId,      
    },
    onSubmit: async (values, { setErrors }) => {
      setIsLoading(true);
      console.log(materialId);
      
      if (materialId) {
        try {
          const rebuildData = (values: any) => {
            let formData = new FormData();
            Object.keys(values).forEach((key) => {
              formData.append(key, values[key]);
            });
            return formData;
          };
          let data = rebuildData(values);
          if (files !== null && files.length != 0) {
            for (let i = 0; i < files.length; i++) {
              data.append("files", files[i]);
            }
            await fetcher({
              key: "rohs/upload",
              data: data,
            });
            await mutate();
            closeModal();
            toast.success("Material created!");
          }else{
            toast.error("Please! Select Pdf File");
          }
          //closeModal();
        } catch (e) {
          setIsLoading(false);
          toast.error("Something went wrong");
        } finally {
          setIsLoading(false);
        }
      }
    },
  });

  return (
    <div className={styles.wrapper}>
      <h2 className="text-4xl leading-10 font-medium mb-2.5 text-primary-500 mb-5">
        Add new Material Declaration
      </h2>
      <form onSubmit={formik.handleSubmit}>
        <div className={"w-full mb-3"}>
          <Input
            type="text"
            label="Type"
            id="type"
            name="type"
            onChange={formik?.handleChange}
            value={formik?.values?.type}
            errorText={formik?.errors?.type}
            placeholder="RoHS / REACH"
          />
        </div>
        {/* <div className={"w-full mb-3"}>
          <Input
            type="text"
            label="Raw Version"
            id="rawVersion"
            name="rawVersion"
            onChange={formik?.handleChange}
            value={formik?.values?.rawVersion}
            errorText={formik?.errors?.rawVersion}
            placeholder="Enter a certificate raw version"
          />
        </div> */}
        <div className={"w-full mb-3"}>
          <Input
            type="text"
            label="Models"
            id="models"
            name="models"
            onChange={formik?.handleChange}
            value={formik?.values?.models}
            errorText={formik?.errors?.models}
            placeholder="Products / Series"
          />
        </div>
        <div className={"w-full mb-3"}>
          <Input
            type="date"
            label="Date of Issue"
            id="dateIssue"
            name="dateIssue"
            onChange={formik?.handleChange}
            value={formik?.values?.dateIssue}
            errorText={formik?.errors?.dateIssue}
            placeholder="Enter date of issue"
          />
        </div>
        <div className={"w-full mb-3"}>
          <Input
            type="date"
            label="Date of Collection"
            id="dateCollection"
            name="dateCollection"
            onChange={formik?.handleChange}
            value={formik?.values?.dateCollection}
            // errorText={formik?.errors?.source}
            placeholder="Enter date of collection"
          />
        </div>
       

        <div className={"mb-5"}>
        <FileUploader
              multiple= {true}
              handleChange={(values: any)=> setFiles([...files,...Array.from(values)]) }
              onSizeError={(err: string) => toast.error(err)}
              onTypeError={(err: string) => toast.error(err)}
              name="file" 
              classes={"DragDropFiles"}
              types={["pdf"]}
              maxSize={10}
              children={
                <div className="DragDropFiles__body">
                  <Upload className="DragDropFiles__icon" />
                  <div className="DragDropFiles__body__title">
                    Upload files <span>or drag and drop</span>
                  </div>
                  <div className="DragDropFiles__body__subtitle">
                    PDF up to 10MB
                  </div>
                </div>
              }
            />
            

            <div >
            { 
            files ? 
              files.map((file: any, index: number)=>{
               return  <div
               key={index}
               className="AddProduct__fileView">
                 <div className="AddProduct__fileView__left">
                  <Document className="AddProduct__fileView__icon" />
                  <div className="AddProduct__fileView__text">{`${file.name}`}</div>
                </div>
                <div
                  className="AddProduct__fileView__right"
                  onClick={() => {
                    const updatedFiles = [...files.slice(0, index), ...files.slice(index + 1)];
 
                    // files.splice(index, 1);
                    console.log(updatedFiles);                    
                    setFiles(updatedFiles)
                  }}
                >
                  Remove
                </div>
                </div>
              
            })
             : null
             
            }
            </div>
        </div>
        <div className={"flex justify-end"}>
        <Button loading={isLoading} label={"Add"} type={"submit"} />
        </div>
      </form>
    </div>
  );
};

export default AddRoHS;
