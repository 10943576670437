import React from "react";
import {LinkProps} from "react-router-dom";
import "./Button.scss";
import classNames from "classnames";

export const BUTTON_COLORS = {
	primary: "primary",
	normal: "normal",
}

export type ButtonProps = {
	label?: string;
	linkTo?: string;
	disabled?: boolean;
	loading?: boolean;
	className?: string;
	icon?: any;
	color?: string;
};

export function Button(
	props: (
		| React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>,
		HTMLButtonElement>
		| LinkProps
		) &
		ButtonProps
) {
	const {
		className,
		icon: Icon,
		color,
		linkTo,
		label,
		disabled,
		loading = false,
		...params
	} = props;

	return (
		<button
			{...(params as any)}
			className={classNames(
				`Button`,
				`${Icon ? 'Button--iconWithText' : ''}`,
				`${color ? `Button--${color}` : `Button--${BUTTON_COLORS.primary}`}`,
				className
			)}
			disabled={disabled || loading}
		>
			{Icon && <div className="Button__icon"><Icon/></div>}
			{!loading ? label : 'Loading...'}
		</button>
	);
}
