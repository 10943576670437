import React from "react";

const NotFound = () => {
  return (
    <div className="w100 min-h-full flex justify-center items-center pt-96">
      <h5 className="text-3xl leading-9 font-semibold">Page not found</h5>
    </div>
  );
};

export default NotFound;
