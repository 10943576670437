import React, { useEffect, useMemo } from "react";
import { createPortal } from "react-dom";
import styles from "./styles.module.scss";
import useLockedBody from "../../hooks/useLockedBody";
import closeIcon from "../../static/assets/png/close.png"
const modalRootElement = document.querySelector("#portal_modal");

const CustomModal = ({
  isShown,
  setModalShown,
  children,
}: {
  children: any;
  setModalShown: () => void;
  isShown: boolean;
}) => {
  useEffect(() => {
    if (isShown && modalRootElement) {
      modalRootElement.appendChild(element);
      return () => {
        modalRootElement.removeChild(element);
      };
    }
  });
  const [locked, setLocked] = useLockedBody();
  useEffect(() => {
    if (isShown) {
      setLocked(true);
    }

    return () => setLocked(false);
  }, [isShown]);

  const element = useMemo(() => document.createElement("div"), []);

  if (isShown && modalRootElement) {
    return createPortal(
      <div className={styles.wrapper} onClick={() => setModalShown()}>
        <div
          onClick={(e) => e.stopPropagation()}
          className={styles.contentWrapper}
        >
          <div className={styles.crossIcon}>
            <img
            onClick={()=>setModalShown()}
            width={15} height={15} src={closeIcon} alt="close icon" />
          </div>
          {children}
        </div>
      </div>,
      modalRootElement
    );
  }
  return null;
};

export default CustomModal;
