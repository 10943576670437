import React from "react";

const Loader = ({ size, absolute = true }: { size?: string, absolute?: boolean }) => {
  return (
    <div className={`${absolute ? "absolute top-0 left-0 right-0 bottom-0" : ""} w-full bg-white h-full z-10 overflow-hidden flex flex-col items-center justify-center`}>
      <div
        style={{
          borderTopColor: "#040C31",
        }}
        className={`ease - linear rounded-full border-4 border-t-4 border-gray-400 ${
          size || "h-12 w-12"
        } animate-spin`}
      />
    </div>
  );
};

export default Loader;
