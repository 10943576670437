import { Button } from "../../components/button/Button";
import { INPUT_SIZE, Input } from "../../components/input/Input";
import RouterLink from "../../components/link/RouterLink";
import { ReactComponent as Logo } from "../../static/assets/svg/logo2.svg";
import { useFormik } from "formik";
import useUser from "../../rohs/api/SWR/useUser";
import * as Yup from "yup";
import forgetPassword from "./forget-password";
import { forgetPasswordApi } from "../../api/apiRequests";
import { toast } from "react-toastify";
import _ from "lodash";
import { forgetPasswordMailApi } from "../../api/SWR/fetchers";
import { m } from "framer-motion";



const validationSchema2 = Yup.object().shape({
	email: Yup.string().email("Invalid email").required("Email is required"),

});
const ForgetForm = () => {
	// const {mutate} = useUser();
	const formik = useFormik({
		validationSchema: validationSchema2,
		initialValues: {
			email: "",

		},
		validateOnChange: false,
		onSubmit: async (values, { setErrors }) => {
			try {
				const response = await forgetPasswordApi.forgetApi(
					values.email,);
				console.log('forget password ====>>', response.data)
				if(response.status==201 && response.data){
					const data=response.data
				const mailResponse=await	forgetPasswordMailApi(data);
				if(mailResponse.status==200){
					toast.success("Check your e-mail to reset password")
				}
				}

			} catch (e) {
				toast.error(_.get(e, 'response.data.message', "Error"));
			}
		},
	});

	return (
		<div className='LoginForm'>

			<RouterLink
				to={"/"}
				label=""
			>
				<Logo className="LoginForm__logo" />
			</RouterLink>

			<div className="LoginForm__title">Forget password </div>
			{/* <div className="LoginForm__subTitle">Or
				<RouterLink
					to={"/"}
					label=" Forget password"
					className='LoginForm__su1bTitle__link'
				/>
			</div> */}

			<form onSubmit={formik.handleSubmit} className='LoginForm__form'>
				{/* {formik?.errors?.email && (
					<div className="LoginForm__textError">{formik.errors.email}</div>
				)} */}

				<Input
					errorText={formik?.errors?.email}
					label="Email address"
					type="email"
					id="email"
					name="email"
					onChange={formik.handleChange}
					value={formik.values.email}
					inputHeight={INPUT_SIZE.large}
				/>

				<Button
					label="Send mail"
					className="LoginForm__button"
					type="submit"
				/>
			</form>
		</div>
	);
};
export default ForgetForm;