import React from "react";
import classNames from "classnames";
import "./Input.scss";

export const INPUT_SIZE = {
	small: 'small',
	large: 'large',
};

export type InputProps = {
	handleClearInput?:React.MouseEventHandler<HTMLDivElement> | undefined;
	label?: string;
	inputValue?:string;
	labelFor?: string;
	errorText?: any;
	validate?: (value: string) => string | undefined;
	inputHeight?: string;
};

export function Input(
	props: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>,
		HTMLInputElement> &
		InputProps
) {
	const {
		handleClearInput,
		inputValue,
		label,
		labelFor,
		errorText,
		inputHeight = INPUT_SIZE.small,
		...params
	} = props;
	return (
		<div className="Input">
			<label
				htmlFor={labelFor}
				className="Input__label"
			>
				{label}
			</label>
			<div className="Input__body">
				<input
					{...params}
					className={classNames(
						`${errorText ? "input-error" : "input-simple px-2"}`,
						`Input--${inputHeight}`,
					)}
				/>
				{
				errorText &&
				 (
					<div className={`Input__errorIcon Input__errorIcon--${inputHeight}`}>
						<svg
							className="h-5 w-5 text-red-500"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 20 20"
							fill="currentColor"
							// aria-hidden="true"
						>
							<path
								fillRule="evenodd"
								d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
								clipRule="evenodd"
							/>
						</svg>
					</div>
				)
				
			}
			{
				inputValue && (
					<div className={`Input__errorIcon Input__errorIcon--${inputHeight}`} onClick={handleClearInput}>
					<svg
					  className="h-5 w-5 text-gray-500"
					  xmlns="http://www.w3.org/2000/svg"
					  viewBox="0 0 20 20"
					  fill="currentColor"
					  aria-hidden="true"
					>
					  <path
						fillRule="evenodd"
						d="M14.293 5.293a1 1 0 011.414 1.414L11.414 12l4.293 4.293a1 1 0 01-1.414 1.414L10 13.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 12 4.293 7.707a1 1 0 011.414-1.414L10 10.586l4.293-4.293a1 1 0 011.414 0z"
						clipRule="evenodd"
					  />
					</svg>
				  </div>
					)
			}
			</div>
			{errorText && (
				<p className="mt-2 text-sm text-error" id="email-error">
					{errorText}
				</p>
			)}
		</div>
	);
}
