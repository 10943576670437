import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ProductType } from "../../types";
import { CERTIFICATE_COLLECTION_STATUSES } from "../../constants/enums";
import useUser from "../../api/SWR/useUser";
import EditProductModalContainer from "../edit-product-modal/EditProductModalContainer";
import CustomModal from "../custom-modal/CustomModal";
import { Button } from "../button/Button";
import { productApi } from "../../api/apiRequests";
import { toast } from "react-toastify";
import { AxiosResponse } from "axios";
import { downloadCSVFile } from "../../api/SWR/fetchers";
import { formateDate } from "../../helper/emailNotification";

function getCertificateCollectionStatus(status: number) {
  switch (status) {
    case CERTIFICATE_COLLECTION_STATUSES.PENDING:
      return "Pending";
    case CERTIFICATE_COLLECTION_STATUSES.READY_FOR_REVIEW:
      return "Ready for review";
    case CERTIFICATE_COLLECTION_STATUSES.COLLECTIONS_ADDITIONAL_CERTIFICATES:
      return "Collecting Additional Certificates";
    case CERTIFICATE_COLLECTION_STATUSES.DONE:
      return "Done";
  }
}

const tableHeadings = [
  "product Name",
  "product Type",
  "Certificate Collection Status",
  "Company Name",
  "Product Created Date",
];
interface certificateCollectionStatusType {
  popup: boolean;
  productId: number;
  checkedValue: number;
}
const ProductsTable = ({
  products,
  mutate,
}: {
  products: ProductType[];
  mutate: any;
}) => {
  const [productToEdit, setProductToEdit] = useState<ProductType | null>(null);
  const { data } = useUser();
  const isAdmin = (data.role === 1);
  const [certificateCollectionStatus, setCertificateCollectionStatus] =
    useState<certificateCollectionStatusType>({
      popup: false,
      productId: 0,
      checkedValue: 0,
    });
  const [productDelete, setProductDelete] = useState({
    popup: false,
    productId: 0,
  });
  const onChangeRadio = (event: any) => {
    setCertificateCollectionStatus({
      ...certificateCollectionStatus,
      checkedValue: parseInt(event.target.value),
    });
  };
  const updateProductStatus = () => {
    productApi
      .updateProductStatus(
        certificateCollectionStatus?.productId,
        certificateCollectionStatus.checkedValue
      )
      .then((response: any) => {
        if (response.result === "success") {
          mutate();
          setCertificateCollectionStatus({
            ...certificateCollectionStatus,
            popup: false,
          });
          toast.success(response.message);
        }
      });
  };
  const certificateCollectionStatusView = () => {
    return (
      <>
        <div className="px-12 py-3">
          <h3 className="text-2xl leading-10 font-medium mb-2.5 text-primary-500">
            Update product certificate status
          </h3>
          <div className="flex items-start flex-col">
            <div className="flex flex-col gap-3">
              <div className="flex gap-3 items-center text-gray-500">
                <input
                  type="radio"
                  value={CERTIFICATE_COLLECTION_STATUSES.PENDING}
                  checked={
                    certificateCollectionStatus.checkedValue ===
                    CERTIFICATE_COLLECTION_STATUSES.PENDING
                  }
                  onChange={onChangeRadio}
                />
                <p>Pending</p>
              </div>
              <div className="flex gap-3 items-center text-gray-500">
                <input
                  type="radio"
                  value={CERTIFICATE_COLLECTION_STATUSES.READY_FOR_REVIEW}
                  checked={
                    certificateCollectionStatus.checkedValue ===
                    CERTIFICATE_COLLECTION_STATUSES.READY_FOR_REVIEW
                  }
                  onChange={onChangeRadio}
                />
                <p>Ready for review</p>
              </div>
              {/* <div className="flex gap-3 items-center text-gray-500">
                <input
                  type="radio"
                  value={
                    CERTIFICATE_COLLECTION_STATUSES.COLLECTIONS_ADDITIONAL_CERTIFICATES
                  }
                  checked={
                    certificateCollectionStatus.checkedValue ===
                    CERTIFICATE_COLLECTION_STATUSES.COLLECTIONS_ADDITIONAL_CERTIFICATES
                  }
                  onChange={onChangeRadio}
                />
                <p>Collecting additional certificates</p>
              </div>
              <div className="flex gap-3 items-center text-gray-500">
                <input
                  type="radio"
                  value={CERTIFICATE_COLLECTION_STATUSES.DONE}
                  checked={
                    certificateCollectionStatus.checkedValue ===
                    CERTIFICATE_COLLECTION_STATUSES.DONE
                  }
                  onChange={onChangeRadio}
                />
                <p>Done</p>
              </div> */}
            </div>
          </div>
          <div className={" mt-4 items-end flex justify-end"}>
            <Button
              label="Update"
              onClick={() => {
                updateProductStatus();
              }}
              // loading={emailTextModal.loader}
            />
          </div>
        </div>
      </>
    );
  };

  const deleteProduct = (productId: number) => {
    productApi.deleteProduct(productId).then((response: any) => {
      if (response) {
        toast.success("Product deleted successfully");
        setProductDelete({ ...productDelete, popup: false });
        mutate();
      } else {
        toast.error("Product not delete please try again.");
        setProductDelete({ ...productDelete, popup: false });
        console.log("product not delete");
      }
    });
  };
  const downloadCSV = async (product: ProductType) => {
    try {
      const fileData: any = await downloadCSVFile(product.id);
      const blob = new Blob([fileData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${product.name}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
    }
  };

  const productDeletePopup = () => {
    return (
      <>
        <div className="flex flex-col gap-10 px-6 py-3">
          <h3 className="text-2xl leading-10 font-medium mb-2.5 text-primary-500">
            Are you sure want to delete this product?
          </h3>
          <div className="flex justify-end gap-2">
            <Button
              color="gray"
              className="border-2 items-center flex"
              label="Cancel"
              onClick={() =>
                setProductDelete({ ...productDelete, popup: false })
              }
            />
            <Button
              label="Delete"
              onClick={() => deleteProduct(productDelete.productId)}
            />
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    {tableHeadings.map((heading) => (
                      <th
                        key={heading}
                        scope="col"
                        className="App__container px-3 py-3 text-left text-xs leading-4 font-black tracking-wider uppercase text-gray-900"
                      >
                        {heading}
                      </th>
                    ))}
                    <th scope="col" className="relative py-3.5 ">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {products.map((product, index) => (
                    
                    <tr
                      key={product.id}
                      className={`${index % 2 === 0 ? undefined : "bg-gray-50"}`}
                    >
                      <td>
                        {!isAdmin && (product?.status?.id === 1||product?.status==null) ? (
                          <span
                            className={
                              "whitespace-nowrap py-4 px-6  text-sm font-medium text-gray-900 sm:pl-6"
                            }
                          >
                            {" "}
                            {product.name}
                          </span>
                        ) : 
                        (
                          <Link
                            to={`products/${product.id}`}
                            className="whitespace-nowrap py-4 px-6  text-sm font-medium text-gray-900 sm:pl-6"
                          >
                            {product.name}
                          </Link>
                        )}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500 ">
                        {product.type?.map((typeItem: any) => (
                          <span
                            key={typeItem.title + typeItem.id}
                          >{`${typeItem.title} `}</span>
                        ))}
                      </td>

                      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                        <div
                            onClick={() => {
                              isAdmin &&
                                setCertificateCollectionStatus({
                                  ...certificateCollectionStatus,
                                  popup: true,
                                  checkedValue: product?.status?.id || 1,
                                  productId: product?.id,
                                });
                            }}
                                          className={`Products__collectionStatus
                            Products__collectionStatus--${
                              product?.status?.id || CERTIFICATE_COLLECTION_STATUSES.PENDING
                            } ${isAdmin ? "cursor-pointer" : "cursor-default"}`}
                          >
                          {getCertificateCollectionStatus(
                            product?.status?.id ||
                              CERTIFICATE_COLLECTION_STATUSES.PENDING
                          )}
                        </div>
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500">
                        {/* {product?.user?.company?.name} */}
                        {product?.user?.company?.name ?? "Certainli"}

                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500">
                        {formateDate(product.createdAt)}
                      </td>
                      <td className="relative whitespace-nowrap  text-right text-sm font-medium ">
                        <div className="flex gap-3 justify-center">
                          {isAdmin && (
                            <button
                              onClick={() => setProductToEdit(product)}
                              className="text-gray-500 hover:text-green-500"
                            >
                              Edit
                              <span className="sr-only">, {product.name}</span>
                            </button>
                          )}
                          {(data.role===3||isAdmin) && (
                            <button
                              onClick={() => downloadCSV(product)}
                              className="text-gray-500 hover:text-green-500"
                            >
                              Download CSV file
                            </button>
                          )}
                          {isAdmin && (
                            <button
                              onClick={() =>
                                setProductDelete({
                                  ...productDelete,
                                  popup: true,
                                  productId: product.id,
                                })
                              }
                              className="text-gray-500 hover:text-red-500"
                            >
                              Delete
                              <span className="sr-only">, {product.name}</span>
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {productToEdit && (
        <EditProductModalContainer
          productID={productToEdit.id}
          setProductToEdit={setProductToEdit}
          mutate={mutate}
        />
      )}
      <CustomModal
        isShown={certificateCollectionStatus.popup}
        setModalShown={() =>
          setCertificateCollectionStatus({
            ...certificateCollectionStatus,
            popup: false,
          })
        }
      >
        {certificateCollectionStatusView()}
      </CustomModal>
      <CustomModal
        isShown={productDelete.popup}
        setModalShown={() => {
          setProductDelete({ ...productDelete, popup: false });
        }}
      >
        {productDeletePopup()}
      </CustomModal>
    </>
  );
};

export default ProductsTable;
