import React from "react";
import "../AddProduct.scss";
import { FormikProps } from "formik";
import AddProductProgressBar, {
  PROGRESS_STATUS,
} from ".././AddProductProgressBar";
import { Input } from "../../input/Input";
import { Textarea } from "../../textarea/Textarea";
import SelectList from "../../selectList/SelectList";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { selectCustomStyles } from "../../../constants/selectStyles";

const testDefaultOptions = [
  { value: 1, label: "value 1" },
  { value: 2, label: "value 2" },
  { value: 3, label: "value 3" },
  { value: 4, label: "value 4" },
];

interface FormValues {
  formik: FormikProps<any>;
  formData: any;
}

const AddProduct1Step = (props: FormValues) => {
  const { formik, formData } = props;
  return (
    <>
      <AddProductProgressBar firstStatus={PROGRESS_STATUS.active} />
      <div className="AddProduct__row AddProduct__row--3column">
        <Input
          type="text"
          label="Product Name/number*"
          id="name"
          name="name"
          onChange={formik?.handleChange}
          value={formik?.values?.name}
          errorText={formik?.errors?.name}
          placeholder="Enter a product Name/number"
        />
        <Input
          type="text"
          label="Product manufacturer*"
          id="manufacturer"
          name="manufacturer"
          onChange={formik?.handleChange}
          value={formik?.values?.manufacturer}
          errorText={formik?.errors?.manufacturer}
          placeholder="Enter a product Manufacturer"
        />

        <div>
          <label>Product Type</label>
          <CreatableSelect
            styles={selectCustomStyles}
            placeholder={"Select product Type"}
            options={formData.productType.map(
              (option: { id: number; title: string }) => ({
                value: option.id,
                label: option.title,
              })
            )}
            onChange={(item: any) => {
              const foundProductType = formData.productType.find((productType: any) => productType.id === item.value)
              const itemId = !foundProductType ? null : item.value
              formik.setFieldValue("type", [
                {
                  id: itemId,
                  title: item.label,
                },
              ]);
            }}
            value={
              formik?.values?.type?.[0]?.id
                ? ({
                    value: formik.values.type[0].id,
                    label: formik.values.type[0].title,
                  } as any)
                : undefined
            }
          />
          <div className="AddProduct__row__description" >
	        Type to create a new option
          </div>
        </div>
      </div>

      <div className="AddProduct__row AddProduct__row--3column">
        <div>
          <label>Product Standards</label>
          <CreatableSelect
            styles={selectCustomStyles}
            placeholder={"Select standards"}
            isMulti
            value={formik.values.standards.map((value: any) => ({
              value: value.id,
              label: value.title,
            }))}
            options={formData.standards.map(
              (option: { id: number; title: string }) => ({
                value: option.id,
                label: option.title,
              })
            )}
            onChange={(item: any) => {
              let newArr;
              if (item) {
                newArr = item.map(
                  (standart: { label: number; value: string; __isNew__: boolean }) => ({
                    id: standart.__isNew__ ? null : standart.value,
                    title: standart.label,
                  })
                );
                formik.setFieldValue("standards", newArr);
              }
            }}
          />
          <div className="AddProduct__row__description" >
              Type to create a new option
          </div>
        </div>
        <div>
          <label>Markets</label>
          <Select
            styles={selectCustomStyles}
            placeholder={"Select one or more"}
            menuPortalTarget={document.body}
            isMulti
            options={formData.markets.map(
              (option: { id: number; title: string }) => ({
                value: option.id,
                label: option.title,
              })
            )}
            value={formik.values.markets.map((value: any) => ({
              value: value.id,
              label: value.title,
            }))}
            onChange={(item: any) => {
              let newArr;
              if (item) {
                newArr = item.map(
                  (market: { label: number; value: string }) => ({
                    id: market.value,
                    title: market.label,
                  })
                );
                formik.setFieldValue("markets", newArr);
              }
            }}
          />
        </div>
        <SelectList
          label="Development status"
          placeholder={"Select one or more"}
          options={formData.developmentStatus.map(
            (option: { id: number; title: string }) => ({
              value: option.id,
              label: option.title,
            })
          )}
          onChange={(item: any) => {
            if (item) {
              formik.setFieldValue("developmentStatus", {
                id: item.value,
                title: item.label,
              });
            }
          }}
          value={
            formik.values?.developmentStatus?.id
              ? {
                  value: formik.values.developmentStatus.id,
                  label: formik.values.developmentStatus.title,
                }
              : undefined
          }
        />
      </div>
      <Textarea
        label="Description"
        id="description"
        name="description"
        placeholder="Enter a product description..."
        value={formik?.values?.description}
        onChange={formik?.handleChange}
      />
    </>
  );
};

export default AddProduct1Step;
