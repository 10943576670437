import LoginFormWrapper from "../login/login-form-wrapper/LoginFormWrapper";
import LoginForm from "../login/login-form/LoginForm";
import ForgetForm from "./forget-form";
import ResetForm from "../reset-password/reset-form";

const ForgetPassword = () => {
    return (
        <LoginFormWrapper>
        <ForgetForm/>
      </LoginFormWrapper>
    );
  };
  
  export default ForgetPassword;